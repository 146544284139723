
import HomePage from '../pages/home.vue';
import Hicapps from '../pages/hicapps.vue';

import Settings from '../pages/settings.vue';
import accountDetail from '../pages/account.vue';
import notifix from '../pages/notifix.vue';
import exportme from '../pages/exportme.vue';
import importme from '../pages/importme.vue';
import reportdetail from '../pages/reportdetail.vue';
import reportdetail_staff from '../pages/reportdetail_staff.vue';



import institution from '../pages/institution.vue';
import students from '../pages/students.vue';
import grade from '../pages/grade.vue';
import newstudent from '../pages/newstudent.vue';
import student from '../pages/student.vue';
import reports from '../pages/reports.vue';
import newstaff from '../pages/newstaff.vue';
import dashboard from '../pages/dashboard.vue';
import staffdailydata from '../pages/staffdailydata.vue';
import visitorsdailydata from '../pages/visitorsdailydata.vue';

import gradedata from '../pages/gradedata.vue';
import allmystaff from '../pages/allmystaff.vue';
import staff from '../pages/staff.vue';
import VisitorsPage from "../pages/visitors.vue";
import questions from '../pages/questions.vue';

import LeftPage1 from '../pages/left-page-1.vue';
import LeftPage2 from '../pages/left-page-2.vue';
import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/staffdailydata/:gdate',
    component: staffdailydata,
  },
  {
    path: '/visitorsdailydata/:gdate',
    component: visitorsdailydata,
  },
  {
    path: '/gradedata/:gradeid/:gdate',
    component: gradedata,
  },
  {
    path: '/dashboard/',
    component: dashboard,
  },
  {
    path: '/hicapps/',
    component: Hicapps,
  },
  {
    path: '/institution/',
    component: institution,
  },

  {
    path: '/importme/',
    component: importme,
  },
  {
    path: '/exportme/',
    component: exportme,
  },
  {
    path: '/students/',
    component: students,
  },
  {
    path: '/reports/',
    component: reports,
  },
  {
    path: '/notifix/',
    component: notifix,
  },
  {
    path: '/newstaff/:precreate/:gradeid',
    component: newstaff,
  },
  {
    path: '/allmystaff/',
    component: allmystaff,
  },
  {
    path: '/visitors',
    component: VisitorsPage
  },
  {
    path: '/questions/',
    component: questions,
  },
  {
    path: '/reportdetail/:studentid',
    component: reportdetail,
  },
  {
    path: '/reportdetail_staff/:staffid',
    component: reportdetail_staff,
  },
  {
    path: '/student/:studentid',
    component: student,
  },
  {
    path: '/staff/:staffid',
    component: staff,
  },
  {
    path: '/settings/',
    component: Settings,
  },
  {
    path: '/account/:accountid',
    component: accountDetail,
  },
  {
    path: '/grade/:gradeid',
    component: grade,
  },
  {
    path: '/newstudent/:gradeid',
    component: newstudent,
  },
  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
