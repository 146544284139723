<template>
  <f7-card>
  <f7-card-header class="cardheaderflex">
       <f7-list class="liboldtitle no-margin-top no-margin-bottom" media-list>
      <f7-list-item
      header="Grade/level"
      :title="grade.nam"
      :footer="'Coordinator: '+((!grade.stas || !Object.keys(grade.stas).length)?'not set':Object.keys(grade.stas).map(one=> one.split('|').join('.')).join(', '))"
      >
      <f7-icon v-if="!mysettings.targetuser" slot="after"  material="group" >
          <f7-badge class="sudobage" :color="grade.count?'teal':'gray'">{{grade.count}}</f7-badge>
        </f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-card-header>
  <f7-card-content>
    <f7-list :class="loading?'skeleton-text skeleton-effect-blink':null" class="fixic datalistxd">
      <f7-list-item
      title="Students cleared"
      footer="Daily checks completed"
      :after="cleared || '0'"
      >
       <f7-icon color="primary" material="assignment_turned_in" slot="media"></f7-icon>
      </f7-list-item>
       <f7-list-item
      title="Admission scans"
      footer="Not available on anonymous mode"
      :after="skns || '0'"
      >
      <f7-icon material="important_devices" slot="media"></f7-icon>
      </f7-list-item>

        <f7-list-item
      title="Absence forms"
      footer="received today"
      :after="abrep || '0'"
      >
       <f7-icon color="orange" material="event_busy" slot="media"></f7-icon>
      </f7-list-item>
    </f7-list>
    </f7-card-content>
  <f7-card-footer>
    <f7-link></f7-link>
    <f7-button :href="'/gradedata/'+grade.gid+'/'+fechforlink" outline>Details</f7-button>
  </f7-card-footer>
</f7-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props:['grade','fechafocus','staff'],
  data(){
    return {
      loading: true,
      cleared: 0,
      abrep: 0,
      skns: 0
    }
  },
  computed:{
    ...mapState(['mysettings','userinfo']),
    fechforlink(){
      return this.fechafocus.split('/').join('-');
    }
  },
  watch:{
    fechafocus(){
      console.log('dtchgh')
      this.getmyinfo();
    }
  },
  mounted(){
    this.getmyinfo();
  },
  methods:{
    getmyinfo(){
      this.loading=true;
      let ahoramom=this.$moment(this.fechafocus,'MM/DD/YYYY');
      let ano = ahoramom.format('YYYY');
      let mes = ahoramom.format('MM');
      let dia = ahoramom.format('DD');
      let laref= 'daily/'+this.userinfo.uid+'/bygr/'+this.grade.gid+'/'+ano+'/'+mes+'/'+dia;
      let abrepref= 'abrep/'+this.userinfo.uid+'/bygr/'+this.grade.gid+'/'+ano+'/'+mes+'/'+dia;
      if(this.mysettings.targetuser){
        laref+='/UNX';
        abrepref+='/UNX';
      }
      else{
        laref+='/VER';
        abrepref+='/VER';
      }

      //ahora scans, si es que es auth
      let promix=[
        this.$firebase.database().ref(laref).once('value'),
        this.$firebase.database().ref(abrepref).once('value')
      ];
      if(!this.mysettings.targetuser){
       promix.push( this.$firebase.database().ref('skns/'+this.userinfo.uid+'/bygr/'+this.grade.gid+'/'+ano+'/'+mes+'/'+dia).once('value') );
      }
      return Promise.all(promix).then(snpsho=>{
        let misdaots= snpsho[0].val() || {};
        let misabs= snpsho[1].val() || {};
        let mykns;
        if(snpsho[2] && snpsho[2].exists()){
          mykns= snpsho[2].val() || {};
          this.skns = Object.keys(mykns).length;
        }
        this.cleared=Object.keys(misdaots).length;
        this.abrep=Object.keys(misabs).length;
        this.loading=false;

       // console.log('cleared',misdaots)
        // console.log('misabs',misabs)
         console.log('mykns',mykns)

      }).catch(error=>{
        console.log(error);
         this.loading=false;
      });
    }
  }
}
</script>
<style>
.datalistxd .item-after span{
  font-size: 18px;
    color: #000;
    font-weight: bold;
}
</style> 