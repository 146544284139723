<template>
  <f7-page name="allmystaff">
    <f7-navbar title="My Staff and Faculty" back-link="Back">
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-onegradestu" icon-material="search"></f7-link>
    </f7-nav-right>
    <f7-searchbar
      class="searchbar-onegradestu"
      expandable
      search-container=".stafflist"
      search-in=".item-title"
    ></f7-searchbar>
    </f7-navbar>

<f7-list class="searchbar-not-found no-margin-top">
    <f7-list-item title="Not found"></f7-list-item>
  </f7-list>
<f7-list media-list class="no-margin-top stafflist searchbar-found">
  <f7-list-item 
     v-for="staff in srtedStaffAll"
      :key="staff.tfid"
  :title="staff.fnm+' '+staff.lnm"
  :footer="staff.leemail"
  :link="'/staff/'+staff.tfid"
  >
    <img src="../static/img/default_original.png" slot="media" class="usricon" />
  </f7-list-item>
</f7-list>


 <f7-fab :href="'/newstaff/0/0'" position="right-bottom" slot="fixed" color="red">
    <f7-icon material="person_add"></f7-icon>
  </f7-fab>
  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
export default {
  data(){
    return {

    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    ...mapGetters(['srtedStaffAll'])
   
  },
  created(){
    
  },
  mounted(){
   this.getmystaff();
  },
  methods:{
    getmystaff(){
        this.$f7.dialog.preloader('Loading...');
          return this.$firebase.database().ref('staff/'+this.userinfo.uid).once('value').then((snapshot)=>{
              this.$f7.dialog.close();
              let alstaf=snapshot.val() || {};
              this.$store.commit('setObjTo',{what:'staff',to:alstaf});
          }).catch(error=>{
              console.log(error);
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,error.code);
          });
    }
  }
}
</script>
<style>

</style>