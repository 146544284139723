<template>
  <f7-page @page:beforein="pedirdats()" name="notifix" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Notifications" subtitle="Contact parents and your faculty/staff with relevant information"></f7-nav-title>
    </f7-navbar>
 <f7-toolbar tabbar top>
    <f7-link tab-link="#emailtabx" tab-link-active>e-mail</f7-link>
    <f7-link tab-link="#smstabx">SMS</f7-link>
  </f7-toolbar>

  <f7-tabs animated>
    <f7-tab id="emailtabx" class="page-content" tab-active>
      
<f7-list inset no-hairlines-md class="no-margin-bottom">
    <f7-list-item title="Send an e-mail" divider></f7-list-item>
  <f7-list-input
    label="Subject"
    type="text"
    placeholder="Short subject"
    clear-button
  >
  </f7-list-input>
 <f7-list-input
    label="Email Body"
    type="textarea"
    placeholder="email body"
  >
  </f7-list-input>
 </f7-list>
<f7-block class="no-margin-top"><f7-button fill>Send Email</f7-button></f7-block>

    </f7-tab>
    <f7-tab id="smstabx" class="page-content">
    
<f7-block>
  <f7-block-title>Send a Text Message</f7-block-title>
  <p>You have 3000 messages left this month</p>
</f7-block>
<f7-list inset no-hairlines-md class="no-margin-bottom">
    <f7-list-item divider title="Select recipients"></f7-list-item>
    <f7-list-input
    label="Staff or parents from grade/level"
    type="select"
    defaultValue=""
    placeholder="Please choose..."
    :value="targetGradeId"
    @input="targetGradeId = $event.target.value"
  >
    <option value="">Please select</option>
    <option v-for="grade in orderedActivegrades" :disabled="phonesbygrd[grade.gid]?null:true"  :key="grade.gid" :value="grade.gid">{{grade.nam}} ({{phonesbygrd[grade.gid]?Object.keys(phonesbygrd[grade.gid]).length:0}})</option>
    <option value="staff" :disabled="phonesbygrd.staff?null:true">All my staff ({{phonesbygrd.staff?Object.keys(phonesbygrd.staff).length:0}})</option>
  </f7-list-input>
    <f7-list-item divider title="Type your SMS. 160 chars max."></f7-list-item>
 <f7-list-input
    label="Message"
    maxlength="160"
    type="textarea"
    :value="lesmss"
    @input="lesmss = $event.target.value"
    placeholder="Short message"
  >
  </f7-list-input>
 </f7-list>
<f7-block class="no-margin-top"><f7-button @click="sendSms()" fill>Send SMS</f7-button></f7-block>

    </f7-tab>
  </f7-tabs>





    


  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';

export default {
       mixins: [loadsettings],

  data (){
    return {
     targetGradeId:'',
     phonesbygrd:{},
     emailsbygrade:{},
     nophones: true,
     lesmss:''
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings','students','staff','myinfo']),
  ...mapGetters(['orderedActivegrades']),

    
  },
  mounted(){
    //
  },
  created(){
    
  },
  methods:{
    sendSms(){
      if(this.nophones){
        return this.$f7.dialog.alert('There are no phones numbers created','No recipients');
      }
      if(!this.targetGradeId){
        return this.$f7.dialog.alert('You must specify a recipient group','No recipients');
      }
      if(!this.lesmss){
        return this.$f7.dialog.alert('Message cannot be empty','No SMS');
      }
      this.$f7.dialog.preloader('Sending...');
      let sms_handler = this.$firebase.functions().httpsCallable('sms_handler');
      sms_handler({clp: this.myinfo.domain, sms: this.lesmss, gid: this.targetGradeId }).then((ladata)=> {
        let payload=ladata.data;
        console.log(payload)
        if(payload.error){
          throw new Error(payload.error);
        }
        this.gid='';
        this.lesmss=''
        this.$f7.toast.show({
          text: 'Message sent',
          position: 'bottom',
          closeTimeout: 2500,
          destroyOnClose: true
        });
        this.$f7.dialog.close();
      }).catch((error)=>{
        this.$f7.dialog.close();
        console.log('error en fx',error);
        this.$f7.dialog.alert(error.message,'Error');
      });

    },
    computarRecipients(){
      let phonesbygrd={};
      let emailsbygrade={};
      let allstudents = Object.values(this.students);
      allstudents.forEach(unstudnt => {
        if(unstudnt.prtphone){
          this.nophones=false
          if(!phonesbygrd[unstudnt.gid]){
            phonesbygrd[unstudnt.gid]={};
          }
          phonesbygrd[unstudnt.gid][unstudnt.prtphone]=true
        }
        if(unstudnt.prtemails){
          if(!emailsbygrade[unstudnt.gid]){
            emailsbygrade[unstudnt.gid]={};
          }
          Object.keys(unstudnt.prtemails).forEach(unemail => {
            emailsbygrade[unstudnt.gid][unemail]=true
          });
        }
      });
      let allstaff = Object.values(this.staff).filter(staff=>!staff.inkt);
      allstaff.forEach(unstaff => {
        if(unstaff.lemobile){
          this.nophones=false
           if(!phonesbygrd.staff){
              phonesbygrd.staff={};
            }
            phonesbygrd.staff[unstaff.lemobile]=true
          }
          if(!emailsbygrade.staff){
             emailsbygrade.staff={}
          }
          emailsbygrade.staff[unstaff.leemail]=true
      });

      this.phonesbygrd = Object.assign({},phonesbygrd)
      this.emailsbygrade = Object.assign({},emailsbygrade)

    },
   pedirdats(){
      this.$f7.dialog.preloader('Loading...');
     return this.loadAllSettings({andstudents: true, andstaff: true, nopreloader: true}).then(()=>{
       return this.$firebase.database().ref('settings/'+this.userinfo.uid+'/consumo/'+this.$moment().format('YYYYMM')).once('value');
     }).then(snpsho=>{
        let predata=snpsho.val() || {}
        console.log(predata)
        this.$f7.dialog.close();
      this.computarRecipients();
     }).catch(error=>{
        console.log(error);
       this.$f7.dialog.close();
       this.$f7.dialog.alert(error.message,error.code)
     })

   }
   

   
  }
}
</script>
<style>

</style>