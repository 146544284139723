import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'bsfadmin',
        userinfo:{},
        appUrl: 'https://admin.besafe.school',
        appVersion: '1.8.5',
        activeTab:'/',
        loginAbierto: true,
        myinfo:{},
        paypal:{},
        suscriptions:{},
        students:{},
        staff:{},
        visitors: {},
        visitorsToday: {},
        pplsandbox: false,
        mapxs:{
          'P-51238273WL099453WL4M7M7A': {type: 'monthly', isSandbox: true},
          'P-0H597147FK2106948L4M6PTQ': {type: 'monthly', isSandbox: false}, // Suscripcion legacy: 299 usd monthly
          'P-0AN13896YN3920256MEJIOQI': {type: 'monthly', isSandbox: false},
          'P-45E94748DA611794NL4NLJOQ': {type: 'yearly', isSandbox: false}, // Suscripcion legacy: 2990 usd yearly
          'P-7PG97188JR8271948L4Y3VUY': {type: 'monthly', isSandbox: false},
          'P-6DE663360H291750RMEJIMJI': {type: 'yearly', isSandbox: false},
        },
        mysettings:{},
        //hicapsp admin
        allAccounts: {}
    },
    getters: {
      orderedActivegrades: (state,getters) => {
        let mygrades= state.mysettings.grades || {};
        let aryadso=Object.values(mygrades) || [];
        let soloactivos = aryadso.filter(ungrade => {
            return !ungrade.inactive
        });
        let concount = soloactivos.map(ungrado => {
          let neox = ungrado;
          neox.count = getters.sudentsByGrade(ungrado.gid).length;
          return neox;
        });
        concount.sort((a, b)=> {
          return a.ord - b.ord;
        });
        return concount;
      },
        mysubscriptions: state => {
            return state.myinfo.subs || {};
        },
        oneStudent: state => {
          return (studentid) => {
              return state.students[studentid];
          }
        },
        oneStaff: state => {
          return (staffemailclean) => {
              return state.staff[staffemailclean];
          }
        },
        sudentsByGrade: state => {
          return (gradeid) => {
              let arreglados =  Object.values(state.students).filter((student) => {
                  return student.gid === gradeid && !student.inkt
              });
              arreglados.sort((a, b) => {
                const x = a.lnm.toLowerCase();
                const y = b.lnm.toLowerCase();
                if(x > y){
                    return 1
                }
                else if(x < y){
                    return -1
                }
                else{
                  const f = a.fnm.toLowerCase();
                  const g = b.fnm.toLowerCase();
                  if(f > g){
                      return 1
                  }
                  else if(f < g){
                      return -1
                  }
                  else{
                      return 0
                  }
                }
            });
              return arreglados;
          }
        },
        sortedStudentsAll: state => {
          let arreglados =  Object.values(state.students).filter(onstu=>{
            return !onstu.inkt
          })
            arreglados.sort((a, b) => {
              const x = a.lnm.toLowerCase();
              const y = b.lnm.toLowerCase();
              if(x > y){
                  return 1
              }
              else if(x < y){
                  return -1
              }
              else{
                const f = a.fnm.toLowerCase();
                const g = b.fnm.toLowerCase();
                if(f > g){
                    return 1
                }
                else if(f < g){
                    return -1
                }
                else{
                    return 0
                }
              }
          });
          return arreglados;
        },
        srtedStaffAll: state => {
          let arreglados =  Object.values(state.staff).filter(staff=>!staff.inkt);
            arreglados.sort((a, b) => {
              const x = a.lnm.toLowerCase();
              const y = b.lnm.toLowerCase();
              if(x > y){
                  return 1
              }
              else if(x < y){
                  return -1
              }
              else{
                const f = a.fnm.toLowerCase();
                const g = b.fnm.toLowerCase();
                if(f > g){
                    return 1
                }
                else if(f < g){
                    return -1
                }
                else{
                    return 0
                }
              }
          });
          return arreglados;
        }

    },
    mutations: {
      setVisitorsToday: (state, visitors) => {
        state.visitorsToday = visitors;
      },
      updtmailsStudent: (state,payload) =>{
        state.students[payload.sid] = Object.assign({}, state.students[payload.sid], { prevemails: payload.to });
      },
      updateStudent: (state,payload) =>{
        let toupdat = {};
        let studentInfo= Object.assign({}, (state.students[payload.sid] || {}), payload.to);
        toupdat[payload.sid] = studentInfo;
        state.students = Object.assign({}, state.students, toupdat);
      },
      updateStaff: (state,payload) =>{
        let toupdat = {};
        let staffinfo= Object.assign({}, (state.staff[payload.tfid] || {}), payload.to);
        toupdat[payload.tfid] = staffinfo;
        state.staff = Object.assign({}, state.staff, toupdat);
      },
      updateGrades: (state,payload) =>{
        let toupdat=Object.assign({},(state.mysettings.grades || {}));
        toupdat[payload.gid] = payload.to;
        state.mysettings = Object.assign({}, state.mysettings, {grades: toupdat});
      },
      updateAllGrades: (state,grades) =>{
        state.mysettings = Object.assign({}, state.mysettings, {grades: grades});
      },
      updateAllQuestions: (state,questions) =>{
        state.mysettings = Object.assign({}, state.mysettings, {qstns: questions});
      },
      updateSTXquestions: (state,payld) =>{
        let neoqu={};
        neoqu[payld.what] = payld.to;
        state.mysettings.qstns = Object.assign({}, state.mysettings.qstns, neoqu);
      },
      updatesymtopm: (state,payload) =>{
        let toupdat={};
        toupdat[payload.i] = payload.to;
        state.mysettings.qstns[payload.stx] = Object.assign({}, state.mysettings.qstns[payload.stx], toupdat);
      },
      updateCoreSetting: (state,payload) =>{
        let toupdat={};
        toupdat[payload.what] = payload.to;
        state.mysettings = Object.assign({}, state.mysettings, toupdat);
      },
      profile_setNeoLogo: (state,neologo) =>{
        state.myinfo = Object.assign({}, state.myinfo, {logo: neologo });
      },
      profile_setNeoDomain: (state,domain) =>{
        state.myinfo = Object.assign({}, state.myinfo, {domain: domain });
      },
      profile_setNeoEmail: (state,email) =>{
        state.myinfo = Object.assign({}, state.myinfo, {email: email });
      },
      profile_setschoolname: (state,schoolname) =>{
        state.myinfo = Object.assign({}, state.myinfo, {schoolname: schoolname });
      },

      profile_startfreetrial: (state,when) =>{
        state.myinfo = Object.assign({}, state.myinfo, {ft: { rq:when } });
      },
      profile_updateSuscription: (state,subscription) =>{
          let subsi = subscription.id;
          let neosuscp={};
          neosuscp[subsi]={meta: subscription};
          let suscriptions = Object.assign({}, (state.myinfo.subs || {}),neosuscp);
        state.myinfo = Object.assign({}, state.myinfo, {subs: suscriptions });
      },
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        pushToArray: (state,payload) => {
          state[payload.array].push(payload.value);
      },
      removeFromObjArray: (state,payload)=>{
        let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
        if(indiexist!=-1){
            state[payload.array].splice(indiexist,1);
        }
    },
        mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        removeFromSimpleArray(state, payload){
            let index = state[payload.array].findIndex((item) =>item === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1);
            }
        },
    },
    actions:{

    }
})