<template>
  <f7-page name="student" @page:beforein="attachObservers()" @page:beforeout="destroyObservers()" >
    <f7-navbar :title="(studentfocuse.fnm+' '+studentfocuse.lnm)" :subtitle="focusedgrade.nam" back-link="Back">
      <f7-nav-right><f7-link @click="deleteThisStudent()" color="gray" icon-material="delete"></f7-link></f7-nav-right>
    </f7-navbar>

<f7-block class="text-align-center no-margin-bottom margin-top">
  <img src="../static/img/default_original.png" class="focusikn" />
</f7-block>
<f7-list media-list class="no-margin">
  <f7-list-item header="First name" :title="studentfocuse.fnm" @click="editstudentprop('fnm')" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
  <f7-list-item header="Last name" :title="studentfocuse.lnm" @click="editstudentprop('lnm')" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
</f7-list>


<f7-block>
  <f7-block-title>Parents/Mentor accounts linkage</f7-block-title>
  <p>There are 2 ways parents can link their accounts to a student entity. If you know the email they will use to access the tool, or by giving them a redeemable code.</p>
  <f7-row>
    <f7-col width="100" medium="50">
      <f7-block-title>Linkage via email</f7-block-title>
      <f7-list media-list class="no-margin liparts">
        <f7-list-item v-for="(status,emailxx) in studentfocuse.prtemails" :key="emailxx" :header="'login with '+emailxx.split('|').join('.')" :footer="status=='p'?'Has not accessed besafe.school yet':'Account linked OK'" @click="removeEmail(emailxx)" link="#" :title="'Status: '+(status=='p'?'Pending':'Linked')">
          <f7-icon :material="status=='p'?'pause_circle_outline':'verified_user'" slot="media"></f7-icon>
          <f7-icon material="delete" slot="after"></f7-icon>
        </f7-list-item>
        <f7-list-button link="#" color="dark-green" @click="addNewEmail()" title="Add email"></f7-list-button>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-block-title>Linkage via a CODE</f7-block-title>
      <f7-list media-list>
        <f7-list-item v-for="code in codesInfo" :key="code" header="Code to manually link account" :title="code"><f7-link  icon-material="delete"  @click="revokeCode(code)" slot="after"></f7-link></f7-list-item>
        <f7-list-button link="#" @click="generateNewCode()" color="dark-green" title="Generate more codes"></f7-list-button>
      </f7-list>
    </f7-col>
  </f7-row>
</f7-block>


<f7-block-title>Parents Contact Phone</f7-block-title>
<f7-list media-list class="no-margin-top">
  <f7-list-item @click="editstudentprop('prtphone')" header="mobile phone" :title="studentfocuse.prtphone || '-'" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
</f7-list>
<f7-block>
  <f7-link @click="loadLog()">View log</f7-link>
</f7-block>


 <f7-sheet class="demo-sheet" :opened="sheetOpened" @sheet:closed="sheetOpened = false">
      <f7-toolbar>
        <div class="left">
        </div>
        <div class="right">
         <f7-link sheet-close>Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-list media-list class="no-margin-top" no-hairlines-md>
        <f7-list-item v-for="(log,indx) in lelog" :key="`log${indx}`" :title="log.em" :subtitle="log.ac" :header="log.wh"></f7-list-item>
        <f7-list-item v-if="!lelog.length" title="No logs for this student"></f7-list-item>
        </f7-list>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
import { deletestu } from '../mixins/deletestu';

export default {
    mixins: [deletestu],
  props: ['studentid'],
  data(){
    return {
     loadingCode: true,
     codesInfo:[],
     sheetOpened: false,
     lelog: [],
     commentsRef: null,
     observacodes: null
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    studentfocuse(){
      return this.$store.getters.oneStudent(this.studentid)
    },
    focusedgrade(){
      return this.mysettings.grades[this.studentfocuse.gid];
    },
  },
  created(){
    console.log(this.studentfocuse)
  },
  methods:{
     deleteThisStudent(){
      this.$f7.dialog.confirm('Are you sure you want to delete this student?','Confirm', ()=> {
       this.$f7.dialog.preloader('Deleting...');
        return  this.deleteStudentsArray([this.studentid]).then((respst)=>{
            this.$f7.dialog.close();
            this.$f7.views.main.router.back();
          }).catch(error=>{
            this.$f7.dialog.close();
            console.log(error)
            this.$f7.dialog.alert(error.message,error.code)
          });
        });
    },
    attachObservers(){
      console.log('encender observables')
       this.commentsRef = this.$firebase.database().ref('students/'+this.userinfo.uid+'/'+this.studentid+'/prtemails');
      this.commentsRef.on('child_added', (data)=> {
        console.log(data.key,data.val())
          let studinfo = Object.assign({},this.studentfocuse)
          let prevemails = studinfo.prtemails || {};
          prevemails[data.key]=data.val()
          this.$store.commit('updtmailsStudent',{sid:this.studentid,to:prevemails});
      });

      this.commentsRef.on('child_changed',  (data)=> {
          let studinfo = Object.assign({},this.studentfocuse)
          let prevemails = studinfo.prtemails || {};
          prevemails[data.key]=data.val()
          this.$store.commit('updtmailsStudent',{sid:this.studentid,to:prevemails});
      });

      this.commentsRef.on('child_removed', (data)=> {
          let studinfo = Object.assign({},this.studentfocuse)
          let prevemails = studinfo.prtemails || {};
          delete prevemails[data.key]
          this.$store.commit('updtmailsStudent',{sid:this.studentid,to:prevemails});
      });

      this.observacodes = this.$firebase.database().ref('codes/'+this.userinfo.uid).orderByChild('stid').equalTo(this.studentid);
        this.observacodes.on('child_added', (data)=> {
        console.log('codes',data.key,data.val())
        if(!this.codesInfo.includes(data.key)){
          this.codesInfo.push(data.key);
        }
      });

      this.observacodes.on('child_changed',  (data)=> {
          if(!this.codesInfo.includes(data.key)){
            this.codesInfo.push(data.key);
          }
      });

      this.observacodes.on('child_removed', (data)=> {
         let index = this.codesInfo.findIndex((code) =>code === data.key);
          if(index !== -1){
              this.codesInfo.splice(index, 1);
          }
      });
    },
    destroyObservers(){
      console.log('desturyendo observables');
      if(this.commentsRef){
        this.commentsRef.off();
      }
      if(this.observacodes){
        this.observacodes.off()
      }
    },
    loadLog(){
       this.$f7.dialog.preloader('Loading...');
       this.lelog=[];
       this.$firebase.database().ref('st_logs/'+this.userinfo.uid+'/'+this.studentid).once('value').then(snpsh=>{
          let lelog=snpsh.val() || {};
          let arrayed = Object.values(lelog) || [];
          let maped = arrayed.map(unlog => {
              return {
                em: unlog.em.split('|').join('.'),
                wh: this.$moment(unlog.wh).format('LLL'),
                ac: unlog.ac
              }
          });
          this.lelog = maped || [];
          this.sheetOpened=true;
           this.$f7.dialog.close();
       }).catch(error=>{
          this.$f7.dialog.close();
           this.$f7.dialog.alert(error.message,error.code);
       });
    },
    generateNewCode(){
      this.$f7.dialog.preloader('Generating...');
      let gencodeforem = this.$firebase.functions().httpsCallable('gencodeforem');
      return gencodeforem({studentid: this.studentid }).then((ladata)=>{
          let payload=ladata.data;
          if(payload.error){
            throw new Error(payload.error);
          }
          this.$f7.dialog.close();
      }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
      });
    },
    removeEmail(emailx){
      this.$f7.dialog.confirm('Are you sure you want to revoke this linked access ('+(emailx.split('|').join('.'))+') ?','Confirm', ()=> {
        console.log('eliminar acceso',emailx);
          this.$f7.dialog.preloader('Revoking...');
          //aqui y en localprivs
          let updates={};
          updates['students/'+this.userinfo.uid+'/'+this.studentid+'/prtemails/'+emailx] = null;
          updates['localprivs/'+this.userinfo.uid+'/'+emailx+'/stus/'+this.studentid] = null;
            this.$firebase.database().ref().update(updates).then(()=>{
              this.$f7.dialog.close();
              //  let studinfo = Object.assign({},this.studentfocuse)
              //  delete studinfo.prtemails[emailx];
              // this.$store.commit('updateStudent',{sid:this.studentid,to:studinfo});
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      });
    },
     revokeCode(code){
      this.$f7.dialog.confirm('Are you sure you want to delete this redeemable code ('+code+') ?','Confirm', ()=> {
         this.$f7.dialog.preloader('Deleting...');
          let disablecode = this.$firebase.functions().httpsCallable('disablecode');
          return disablecode({studentid: this.studentid, code: code }).then((ladata)=>{
              let payload=ladata.data;
              if(payload.error){
                throw new Error(payload.error);
              }
              this.$f7.dialog.close();
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      });
    },
    editstudentprop(cual){
      let frasex={
        fnm: 'Edit first name',
        lnm : 'Edit last name',
        prtphone: 'Edit mobile phone'
      };
      this.$f7.dialog.prompt(frasex[cual], (name)=> {
        if(!name){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          name = name.trim()
          this.$f7.dialog.preloader('Updating...');
            this.$firebase.database().ref('students/'+this.userinfo.uid+'/'+this.studentid+'/'+cual).set(name).then(()=>{
              this.$f7.dialog.close();
              let studinfo = Object.assign({},this.studentfocuse)
              studinfo[cual] = name;
              this.$store.commit('updateStudent',{sid:this.studentid,to:studinfo});
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
        }
      },()=>{},this.studentfocuse[cual]);
    },
    addNewEmail(){
      this.$f7.dialog.prompt('Add new email for linkage', (name)=> {
        if(!name){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          name = name.trim()
          let cleanemail=name.split('.').join('|').toLowerCase();
          this.$f7.dialog.preloader('Updating...');
          let updates = {};
          updates['students/'+this.userinfo.uid+'/'+this.studentid+'/prtemails/'+cleanemail] = 'p';
          updates['localprivs/'+this.userinfo.uid+'/'+cleanemail+'/stus/'+this.studentid] = true;
          return this.$firebase.database().ref().update(updates).then(()=>{
              this.$f7.dialog.close();
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
        }
      });
    }
  }
}
</script>
<style>
.focusikn{
width: 130px;
    border-radius: 50%;
}
.liparts .item-media .icon{
  margin: 0 auto;
  font-size: 35px;
  margin-top: 6px;
}
</style>