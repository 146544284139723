<template>
    <f7-list>
        <f7-list-item divider title="Impersonate"></f7-list-item>
        <f7-list-item>
            <f7-list-input
                type="email"
                @input="email = $event.target.value"
                :value="email"
                placeholder="Add an authorized email"
            ></f7-list-input>
            
            <f7-button
                @click="addEmail"
                :disabled="! email"
            >
                Add
            </f7-button>
        </f7-list-item>

        <f7-list-item
            v-for="email in authorizedEmails"
            :key="email"
        >
            {{ formatEmail(email) }}
            <f7-button
                @click="deleteAuthorizedEmail(email)"
                color="red"
            >
                Delete
            </f7-button>
        </f7-list-item>
    </f7-list>
</template>

<script>
export default {
    data: function () {
        return {
            email: '',
            authorizedEmails: [],
            authorizedEmailsRef: null,
        };
    },

    props: ['shouldDestroy'],

    mounted: function () {
        this.setupAuthorizedEmailsRef();
        this.fetchAuthorizedEmails();
    },

    methods: {
        /**
         * Setup listener for the impersonators database node
         */
        setupAuthorizedEmailsRef: function () {
            const adminUid = this.$firebase.auth().currentUser.uid;
            this.authorizedEmailsRef = this.$firebase.database().ref('/imp/').orderByChild('uid').equalTo(adminUid);
        },

        /**
         * Detach the listener for the impersonators database node
         */
        destroyAuthorizedEmailsRef: function () {
            if(this.authorizedEmailsRef) {
                this.authorizedEmailsRef.off();
            } 
        },

        //
        addEmail: async function () {
            this.$f7.dialog.preloader('Authorizing email...');

            // the admin to be impersonated
            const adminUid = this.$firebase.auth().currentUser.uid;

            const formattedEmail = this.email.replaceAll('.', '|')

            this.$firebase.database().ref(`/imp/${formattedEmail}`).set({ uid: adminUid }, error => {
                if (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert('There was an error while authorizing this email.' + error);
                } else {
                    this.email = '';
                    this.$f7.dialog.close();
                }
            });
        
        },

        fetchAuthorizedEmails: function () {
            this.authorizedEmailsRef.on('child_added', snapshot => {
                this.authorizedEmails.push(snapshot.key);
            });
        },

        deleteAuthorizedEmail: function(authorizedEmail) {
            this.$f7.dialog.preloader('Removing authorized email...');

            this.$firebase.database().ref(`/imp/${authorizedEmail}`).remove().then(() => {
                this.authorizedEmails = this.authorizedEmails.filter(email => email != authorizedEmail);
                this.$f7.dialog.close();
            }).catch(error => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert('Error while removing authorized email.' + error);
            });
            
        },

        formatEmail: function(email) {
            return email.replaceAll('|', '.');
        }
    },
    
    watcher: {
        shouldDestroy: {
            immediate: true,
            handler: function() {
                this.destroyAuthorizedEmailsRef();
            }
        }
    }
}
</script>