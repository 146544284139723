<template>
    <f7-page name="account_home" @page:beforeout="leaving = true">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Account</f7-nav-title>
        </f7-navbar>

        <!-- Page content-->
        <f7-block class="margin-bottom-half">
            <f7-row>
                <!-- Account info section -->
                <f7-col width="100" medium="50">
                    <f7-list class="profile no-margin-top" >
                        <f7-list-item divider title="Account Info"></f7-list-item>
                        <f7-list-item header="Account Owner" :footer="userinfo.uid" :title="userinfo.email">
                            <f7-icon class="text-color-gray" slot="media" material="email"></f7-icon>
                        </f7-list-item>
                        <f7-list-item @click="chgInstitutionName()" link="#" header="Institution name" :title="myinfo.schoolname" >
                            <f7-icon class="text-color-gray" slot="media" material="account_balance"></f7-icon>
                            <f7-icon v-if="!myinfo.schoolname" slot="after" material="create"></f7-icon>
                                <f7-icon v-else slot="after" class="text-color-teal" material="check"></f7-icon>
                        </f7-list-item>
                        <f7-list-item @click="chsubgdomain()" link="#" :header="`Subdomain (${myinfo.domain || 'schoolname'}.besafe.school)`" :title="myinfo.domain">
                            <f7-icon class="text-color-gray" slot="media" material="public"></f7-icon>
                            <f7-icon v-if="!myinfo.domain" slot="after" material="create"></f7-icon>
                            <f7-icon v-else slot="after" class="text-color-teal" material="check"></f7-icon>
                        </f7-list-item>
                        <f7-list-input
                            label="Timezone"
                            type="select"
                            placeholder="Please choose..."
                            :value="mysettings.tz || ''"
                            @input="savetimezone($event.target.value)"
                        >
                            <f7-icon class="text-color-gray relojmasarriba" material="access_time" slot="media"></f7-icon>
                            <option value="">Please select</option>
                            <option v-for="timz in alltimezones" :key="timz" :value="timz">{{timz}}</option>
                        </f7-list-input>
                    </f7-list>

                    <f7-block v-if="!myinfo.domain || !myinfo.schoolname" class="warniron">Please provide a school name and a subdomain before starting your subscription.</f7-block>
                    
                    <f7-block v-if="!myinfo.status && (anysusactive || (freetrialInfo.rq && !myinfo.vt))" class="alerton">Your account is being provisioned. The process can take up to 24 hours (sudomains, DNS, SSL). You will be notified once it has been deployed.</f7-block>
                </f7-col>
                
                <!-- School banner section -->
                <f7-col width="100" medium="50">
                    <f7-card class="no-margin">
                        <f7-card-header class="cardheaderflex"> <f7-list class="no-margin">
                        <f7-list-item divider title="School banner"></f7-list-item></f7-list><input type="file" id="myimg-input" @change="filesChange($event)" accept="image/png" /></f7-card-header>
                        <f7-card-content class="maxaltofoto" :padding="false">
                            <img class="limitlogo" :src="myinfo.logo || '../static/img/default_logo.png'"/>
                        </f7-card-content>
                        <f7-card-footer>
                            <span>916 x 280 px .PNG, under 10MB</span>
                            <f7-button @click="hacerclick()" outline>Upload New</f7-button>
                        </f7-card-footer>
                    </f7-card>
                </f7-col>
            </f7-row>
        </f7-block>

        <!-- Impersonate user section -->
        <f7-block>
            <f7-row>
                <f7-col width="100" medium="50">
                    <impersonate-users v-if="userinfo.uid" :should-destroy="leaving"></impersonate-users>
                </f7-col>
            </f7-row>
        </f7-block>
  
        <!-- Subscription status section -->
        <f7-block class="margin-half">
            <f7-block-title>Subscription status</f7-block-title>
            <f7-list media-list class="no-margin">
                <f7-list-item divider title="Besafe.school account"></f7-list-item>
                <f7-list-item v-if="anysusactive" title="Status" :footer="(myinfo.domain || 'subdomain') +'.besafe.school'" badge-color="blue" :badge="domainAlive?'Active':'Deploying...'" ></f7-list-item>
                <f7-list-item v-else title="Status" :footer="(myinfo.domain || 'subdomain') +'.besafe.school'" :badge-color="myinfo.status=='Canceled'?'red':(!myinfo.status && (anysusactive || (freetrialInfo.rq && !myinfo.vt))?'yellow':(!myinfo.status && !anysusactive?'gray':'blue'))" :badge="myinfo.status?myinfo.status:((anysusactive || (freetrialInfo.rq && !myinfo.vt))?'Deploying...':'Unsubscribed')" ></f7-list-item>
                <f7-list-item v-if="freeTrialValid && !anysusactive"  title="Trial valid thru" :after="freeTrialValid"></f7-list-item>
            </f7-list>
            <f7-list v-for="(suscr,suscrid) in mysubscriptions" media-list :key="suscrid" class="no-margin">
                <f7-list-item divider title="Paypal Subscription"></f7-list-item>
                <f7-list-item title="Status" :footer="suscr.meta.subscriber?suscr.meta.subscriber.email_address:null" :badge-color="suscr.meta.status=='ACTIVE'?'blue':'red'" :badge="suscr.meta.status"></f7-list-item>
                <f7-list-item v-if="suscr.meta.status=='ACTIVE'"  title="Plan" :after="mapxs[suscr.meta.plan_id].type"></f7-list-item>
                <f7-list-item v-if="false && suscr.meta.status=='ACTIVE'" title="Next billing" :after="suscr.meta.billing_info.next_billing_time.split('T')[0]">
                    <f7-chip slot="subtitle" v-if="$moment(suscr.meta.billing_info.next_billing_time).isBefore($moment())" text="Overdue" color="red"></f7-chip>
                    <f7-chip slot="subtitle" v-else :title="$moment(suscr.meta.billing_info.next_billing_time).fromNow()"></f7-chip>
                </f7-list-item>
                <f7-list-button v-if="suscr.meta.status=='ACTIVE'"  @click="prontconfirm(suscrid)" color="red">Cancel subscription</f7-list-button>
            </f7-list>
        </f7-block>

        <!-- Start subscription section -->
        <f7-block v-if="!anysusactive">
            <f7-row>
                <f7-col width="100" medium="50">
                    <f7-list class="no-margin-top">
                        <f7-list-item divider title="Start subscription (USD)"></f7-list-item>
                            <li class="item-content item-input">
                                <div class="item-inner">
                                    <div class="item-title item-label">COUPON</div>
                                        <div class="item-input-wrap">
                                            <input v-model="coupon" type="text" name="coupon">
                                            <span class="input-clear-button"></span>
                                        </div>
                                    </div>
                                <div class="item-after"><f7-button @click="attemptCoupon()" outline >Redeem</f7-button></div>
                            </li>
                        <f7-list-item v-if="Object.keys(mysubscriptions).length==0 && !freeTrialValid" radio value="trial" name="substype" :checked="substypex === 'trial'" @change="chagsbusctypex($event.target.value)" title="Free Trial" footer="Get 14 days to try besafe.school" after="0"></f7-list-item>
                        <f7-list-item radio v-if="dsk" value="monthly_discount" name="substype" :checked="substypex === 'monthly_discount'" @change="chagsbusctypex($event.target.value)" header="Discounted"  title="Monthly (17% OFF)" footer="Monthly payments of 299" after="299"></f7-list-item>
                        <f7-list-item radio value="monthly" name="substype" :checked="substypex === 'monthly'" @change="chagsbusctypex($event.target.value)"  title="Monthly" footer="Monthly payments of 329" after="329"></f7-list-item>
                        <f7-list-item radio value="yearly" name="substype" :checked="substypex === 'yearly'" @change="chagsbusctypex($event.target.value)" title="Yearly" footer="Get 12 months for the price of 10" after="3290"></f7-list-item>
                    </f7-list>
                </f7-col>

                <f7-col width="100" medium="50">
                    <f7-block class="text-align-center" v-if="substypex=='trial'"><p>No Credit Card Required</p> <f7-button  round large class="margin-top" outline @click="startFreeTrial()">Start 14 days Free Trial</f7-button></f7-block>
                    <f7-block v-show="substypex && substypex!='trial'">
                        <div id="paypal-button-container"></div>
                    </f7-block>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';

import ImpersonateUsers from '../components/impersonate/ImpersonateUsers.vue'

export default {
    mixins: [loadsettings],
    
    data () {
        return {
            progressdialog:null,
            task: null,
            substypex: null,
            madeppvisible: false,
            alltimezones: this.$moment.tz.names(),
            dsk:null,
            domainValidThru: null,
            domainAlive: null,
            coupon:'',
            leaving: false,
        }
    },
    
    components: {
        ImpersonateUsers
    },

    computed: {
        ...mapState(['userinfo','myinfo','mapxs','mysettings','pplsandbox']),

        ...mapGetters(['mysubscriptions']),

        anysusactive() {
            let sus = Object.values(this.mysubscriptions);
                return sus.some(suscr => {
                    return suscr.meta.status=='ACTIVE'
                });
        },

        freetrialInfo(){
            return this.myinfo.ft || {};
        },

        freeTrialValid(){
            if(this.freetrialInfo.rq && !this.domainValidThru){
                return this.$moment(this.freetrialInfo.rq).add(15,'d').format('LL');
            } else if(this.domainValidThru && this.$moment(this.domainValidThru,'YYYY-MM-DD').isAfter(this.$moment())){
                return this.$moment(this.domainValidThru,'YYYY-MM-DD').format('LL');
            } else{
                return false;
            }
        },

        mydomain(){
            return this.myinfo.domain
        }
    },

    watch: {
        mydomain(newval){
            if(newval){
                this.traerDominioifn();
            }
        }
    },

    mounted() {
        console.log(this.userinfo);
        document.tidioIdentify = {
            distinct_id: this.userinfo.uid, // Unique user ID in your system
            email: this.userinfo.email, // User email
            name: this.userinfo.displayName // Visitor name
        };
        this.traerDominioifn();
    },

    methods: {
        traerDominioifn() {
            if(this.myinfo.domain){
                return this.$firebase.database().ref('domains/'+this.myinfo.domain).once('value').then(snpsho=>{
                    let linfo = snpsho.val() || {};
                    console.log('linfo',linfo)
                    if(linfo.u && linfo.u==this.userinfo.uid && linfo.vt){
                        console.log('vt',linfo.vt)
                        this.domainValidThru=linfo.vt;
                        this.domainAlive = true
                    }
                }).catch(error=>{
                    console.log(error)
                });
            }
        },

        attemptCoupon(){
            if(!this.coupon){
                return this.$f7.dialog.alert('Please type a code','Empty');
            }

            let lecoupon = this.coupon.toUpperCase();
            this.$f7.dialog.preloader('Validating...');
            this.$firebase.database().ref('coupons/'+lecoupon).once('value').then(snpsho=>{
                if(!snpsho.exists()){
                    throw new Error('Coupon does not exist');
                }
                this.substypex === 'monthly_discount'
                this.coupon = ''
                this.dsk=snpsho.val();
                this.$f7.dialog.close();
            }).catch(error=>{
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            });
        },
    
        savetimezone(mytimeon){
            console.log('disaprando cambio tzz',mytimeon)
            this.$firebase.database().ref('settings/'+this.$firebase.auth().currentUser.uid+'/tz').set(mytimeon).then(()=>{
                console.log('update ahora settings')
                this.$store.commit('updateCoreSetting',{what:'tz',to:mytimeon});
                this.$f7.toast.show({
                    text: 'Timezone saved',
                    position: 'center',
                    destroyOnClose: true,
                    closeTimeout: 2000,
                });
            }).catch(error=>{
                console.log('error saving timezone',error);
            });
        },

        startFreeTrial(){
            this.$f7.dialog.preloader('Requesting...');
            return this.loadAllSettings({nopreloader:true}).then(()=>{
                this.$firebase.database().ref('myinfo/'+this.$firebase.auth().currentUser.uid+'/ft/rq').set(this.$firebase.database.ServerValue.TIMESTAMP );
            }).then(()=>{
                this.$f7.dialog.close();
                let toastCenter = this.$f7.toast.show({
                    text: 'Free Trial requested',
                    position: 'bottom',
                    closeTimeout: 2500,
                    destroyOnClose: true
                });
                this.$store.commit('profile_startfreetrial', Date.now());
            }).catch(error=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,'Error');
            });
        },

        prontconfirm(susID){
            this.$f7.dialog.confirm('Your account will be inactivated','Are you sure?', ()=> {
                this.cancelsuscription(susID);
            });
        },

        cancelsuscription(susID){
            this.$f7.dialog.preloader('Validating...');

            let cancelsubsc = this.$firebase.functions().httpsCallable('cancelsubsc');

            cancelsubsc({susID: susID, t: true }).then((ladata)=> {
                console.log('llego data',ladata);
                let payload=ladata.data;
                if(payload.error){
                    throw new Error(payload.error);
                }
                let linfo = payload.succes;
                this.$store.commit('profile_updateSuscription', linfo);
                this.$f7.dialog.close();
            }).catch((error)=>{
                console.log('error en fx',error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,'Error');
            });
        },

        chagsbusctypex(neotype){
            this.substypex = neotype;
            console.log('setting sus type to ', neotype)
            if(!this.madeppvisible){
                this.displayPaypalButtons();
                this.madeppvisible = true;
            }
        },

        checkMySubscription(susID){
            this.$f7.dialog.preloader('Validating...');
            let subxp = this.$firebase.functions().httpsCallable('subxp');
            subxp({susID: susID, t: this.pplsandbox }).then((ladata)=> {
                console.log('llego data',ladata);
                let payload=ladata.data;
                if(payload.error){
                    throw new Error(payload.error);
                }
                let linfo = payload.succes;
                this.$store.commit('profile_updateSuscription', linfo);
                this.$f7.dialog.close();
            }).catch((error)=>{
                console.log('error en fx',error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,'Error');
            });
        },

        displayPaypalButtons() {
            window.paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe',
                },
                createSubscription: (data, actions) => {
                    if(this.pplsandbox){
                        return actions.subscription.create({
                            'plan_id': this.substypex=='monthly'?'P-51238273WL099453WL4M7M7A':null
                        });
                    } else{
                        let planid = this.dsk?this.dsk:(this.substypex=='monthly'?'P-0AN13896YN3920256MEJIOQI':'P-6DE663360H291750RMEJIMJI');
                        console.log('asking for planid',planid)
                        return actions.subscription.create({
                            'plan_id': planid
                        });
                    }
                },
                //P-51238273WL099453WL4M7M7A   //-SANDBOX monthly
                //P-0H597147FK2106948L4M6PTQ  //->PROD monthly
                //'P-45E94748DA611794NL4NLJOQ' // prod yearly
                onApprove: (data, actions) => {
                    console.log(data.subscriptionID);
                    this.checkMySubscription(data.subscriptionID);
                },
                onError: (err) => {
                    // Show an error page here, when an error occurs
                    this.$f7.alert(err.message || 'Something went wrong. Please contact support');
                }
            }).render('#paypal-button-container');
        },

        hacerclick(){
            this.$$('#myimg-input').click();
        },

        filesChange (e) {
            let file = e.target.files[0];
            if (file) {
                console.log(file)
                this.subirfoto(file, file.name)
            }
        },

        subirfoto (filereceive, filename) {
            this.progressdialog = this.$f7.dialog.progress('Uploading image', 0);
            this.progressdialog.setText('0%');
            this.task = this.$firebase.storage().ref('logos/' + this.$firebase.auth().currentUser.uid + '/logo.png').put(filereceive);
            this.task.on('state_changed',
                this.alsnapshot,
                this.alerror,
                this.altermino
            );
        },

        alsnapshot (snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + '%');
            }
        },

        alerror (err) {
            console.log(err);
            this.progressdialog.close();
            this.$f7.dialog.alert('Make sure is a PNG format, and less than 10 MB',err.code);
        },

        altermino () {
            let glbldownloadURL = null;
            this.progressdialog.close();
            this.$f7.dialog.preloader('Processing...');
            console.log('se subio la foto!');
            return this.task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                console.log('File available at', downloadURL);
                glbldownloadURL = downloadURL
                return this.$firebase.database().ref('myinfo/'+this.$firebase.auth().currentUser.uid+'/logo').set(glbldownloadURL);
            }).then(()=>{
                this.$f7.dialog.close();
                this.$store.commit('profile_setNeoLogo', glbldownloadURL);
                let toastCenter = this.$f7.toast.show({
                    text: 'Saved OK',
                    position: 'bottom',
                    closeTimeout: 2500,
                    destroyOnClose: true
                });
            }).catch(error=>{
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message,error.code);
            });
        },

        chsubgdomain(){
            this.$f7.dialog.prompt('Edit subdomain', (name)=> {
                let trimmed=name.trim();
                if(!trimmed){
                    return this.$f7.dialog.alert('This can not be empty!');
                } else {
                    trimmed=trimmed.split('.')[0];
                    this.$f7.dialog.preloader('Saving...');
                    this.$firebase.database().ref('domains/'+trimmed).once('value').then(snpahs=>{
                        if(snpahs.exists()){
                            throw new Error('subdomain is already in use. Please choose a different one.');
                        } else{
                            return this.$firebase.database().ref('myinfo/'+this.userinfo.uid+'/domain').set(trimmed);
                        }
                    }).then(()=>{
                        console.log(trimmed+' saved!');
                        this.$f7.dialog.close();
                        let toastCenter = this.$f7.toast.show({
                            text: 'Saved OK',
                            position: 'bottom',
                            closeTimeout: 2500,
                            destroyOnClose: true
                        });
                        //profile_setNeoDomain
                        this.$store.commit('profile_setNeoDomain', trimmed);
                    }).catch(error=>{
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message,error.code || 'Error');
                    });
                }
            },()=>{},this.myinfo.domain);
        },

        chgInstitutionName(){
            this.$f7.dialog.prompt('Edit institution name', (name)=> {
                let trimmed=name.trim();
                if(!trimmed){
                    return this.$f7.dialog.alert('This can not be empty!');
                } else {
                    this.$firebase.database().ref('myinfo/'+this.userinfo.uid+'/schoolname').set(trimmed).then(()=>{
                        console.log(trimmed+' saved!');
                        let toastCenter = this.$f7.toast.show({
                            text: 'Saved OK',
                            position: 'bottom',
                            closeTimeout: 2500,
                            destroyOnClose: true
                        });
                        this.$store.commit('profile_setschoolname', trimmed);
                    }).catch(error=>{
                        console.log(error);
                        this.$f7.dialog.alert(error.message,error.code);
                    });
                }
            },()=>{},this.myinfo.schoolname);
        },
    }
}
</script>

<style>
.max40 {
    width:40px;
}

.someincomplete {
    background: #ffe5e5;
    margin-top: 0px;
    color: #940505;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.limitlogo {
    width: 100%;
    max-width: 916px;
}

.maxaltofoto {
    text-align: center;
}

#myimg-input {
    display: none!important;
}

.alerton {
    background: #ffd1d1;
    color: #860000;
    padding: 12px;
    font-weight: bold;
}

.warniron {
    background: #ffedd5;
    color: #6f3104;
    padding: 12px;
    font-weight: bold;
}

.relojmasarriba{
    top: -10px!important;
}
</style>