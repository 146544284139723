<template>
  <f7-page name="importme">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Data Import" subtitle="Load your students from an xlsx file"></f7-nav-title>
    </f7-navbar>

<f7-block>In this module, you can speed up the student (and linked parents) creation process. You will be able to upload one .XLSX template PER GRADE.</f7-block>

 <f7-row>
    <f7-col width="100" medium="50">
      <f7-block-title>First, download a xlsx template to populate</f7-block-title>
        <f7-block>
          <f7-button href="https://firebasestorage.googleapis.com/v0/b/besafe-core.appspot.com/o/template.xlsx?alt=media&token=c661c0f2-2453-410e-8244-ecc87240e13a" target="_blank" external outline>Download template</f7-button>
        </f7-block>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-block>On the downloaded file, fill the data in the specified order (student first name, student last name, parent email, parent email, parent phone)</f7-block>
    </f7-col>
  </f7-row>



<f7-block>
  <f7-list no-hairlines-md class="no-margin-bottom">
    <f7-list-item divider title="1.- Select the grade to import"></f7-list-item>
    <f7-list-input
    label="Grade/level"
    type="select"
    defaultValue=""
    placeholder="Please choose..."
    :value="targetGradeId"
    @input="targetGradeId = $event.target.value"
  >
    <option value="">Please select</option>
    <option v-for="grade in orderedActivegrades"  :key="grade.gid" :value="grade.gid">{{grade.nam}}</option>
  </f7-list-input>
    <f7-list-item divider title="2.- Select the XLSX file."></f7-list-item>
    <f7-list-item>
      <input type="file" accept=".xlsx" @change="newfilw" id="xlsimport" />
    </f7-list-item>
     <f7-list-item divider title="3.- Preview your import."></f7-list-item>
  </f7-list>
  <div class="data-table card no-margin-top">
  <table>
    <thead>
      <tr>
        <th class="label-cell">Student name</th>
        <th>Email 1</th>
        <th>Email 2</th>
        <th>Phone</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(unimport,idx) in toimport" :key="idx">
        <td :class="{'text-color-red':unimport.error}" class="label-cell">{{unimport.readname}}</td>
        <td :class="{'text-color-deeporange':unimport.emailone_error}">{{unimport.emailone}}</td>
        <td :class="{'text-color-deeporange':unimport.emailtwo_error}">{{unimport.emailtwo}}</td>
         <td>{{unimport.prtphone}}</td>
        <td>{{unimport.error?'Error':'OK'}}</td>
      </tr>
    </tbody>
  </table>
</div>
</f7-block>
<f7-block v-if="processed">
  <f7-block-footer v-if="toimport.length">Red rows means missing data for name or last name for the student, and won't be imported. Orange cells represent unvalid email addresses (import will occur, withouth that email).</f7-block-footer>
</f7-block>

<f7-block-title>4.- Execute the import.</f7-block-title>
<f7-block v-if="processed && targetGradeId">
  <p class="bold text-align-center">{{importable.length}} students will be imported to the {{$store.state.mysettings.grades[targetGradeId].nam}} grade</p>
  <f7-button :class="{disabled: !processed}" @click="proceedToImport()" fill>Import and Save</f7-button></f7-block>
<f7-block><p><span v-if="!targetGradeId">Please select the grade/level to import to on step 1. </span><span v-if="!processed">Please select the file to import on step 2.</span></p></f7-block>


  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import readXlsxFile from 'read-excel-file'

import * as EmailValidator from 'email-validator';

export default {
  data (){
    return {
      toimport:[],
      processed: false,
      targetGradeId: ''
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings']),
     ...mapGetters(['orderedActivegrades']),
     importable(){
        return this.toimport.filter(oneimp=>{
          return !oneimp.error
        })
     }
    
  },
  mounted(){
       },
  created(){
  
  },
  methods:{
    proceedToImport(){
      if(!this.targetGradeId){
        return this.$f7.dialog.alert('You must specify the target grade/level','Please select a grade');
      }
      if(!this.importable.length){
        return this.$f7.dialog.alert('There is  no valid data to import','Please check the source file');
      }
      console.log('hay '+this.importable.length+' para importar');
      console.log('vamos a importar ',this.importable)
       this.$f7.dialog.preloader('Creating...');
        let updates = {};
        let palstore = {};
         
      this.importable.forEach(unpotstu => {
          let studentid = this.$firebase.database().ref('students/'+this.userinfo.uid).push().key;
          let toupdate = {
            fnm: unpotstu.fnm.trim(),
            lnm: unpotstu.lnm.trim(),
            sid: studentid,
            gid: this.targetGradeId,
            prtemails: {},
            prtphone: unpotstu.prtphone || null
          };
          if(unpotstu.emailone && !unpotstu.emailone_error){
            let leoneclean = unpotstu.emailone.split('.').join('|');
            toupdate.prtemails[leoneclean]='p';
            updates['localprivs/'+this.userinfo.uid+'/'+leoneclean+'/stus/'+studentid] = true;
          }
          if(unpotstu.emailtwo && !unpotstu.emailtwo_error){
            let leoneclean2 = unpotstu.emailtwo.split('.').join('|');
            toupdate.prtemails[leoneclean2]='p';
            updates['localprivs/'+this.userinfo.uid+'/'+leoneclean2+'/stus/'+studentid] = true;
          }
           updates['students/'+this.userinfo.uid+'/'+studentid] = toupdate;
           palstore[studentid] = toupdate;
      });
       return this.$firebase.database().ref().update(updates).then(()=>{
          this.$f7.dialog.close();
          this.$store.commit('mergeObjTo',{what:'students',to:palstore});
          this.toimport = [];
          this.processed = false;
          this.targetGradeId = '';
          this.$f7.dialog.alert('Students created. Go to the corresponding grade to view the updated list.','Success');
      }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
      });
    },
   newfilw(e){
     let file = e.target.files[0];
      if (file) {
          console.log(file)
         return readXlsxFile(file).then((rows) => {
          rows.forEach(onerow => {
            if(onerow[0]!=='First Name'){
              let toupdate = {
                  fnm: null,
                  lnm: null,
                  readname: '',
                  emailone: '',
                  emailone_error: null,
                  emailtwo: '',
                  emailtwo_error: null,
                  prtphone: '',
                  error: false
                };
                if(!onerow[0]){
                  toupdate.error=true;
                }
                else{
                   toupdate.fnm=onerow[0];
                   toupdate.readname+=onerow[0]+' ';
                }
                if(!onerow[1]){
                  toupdate.error=true;
                }
                else{
                   toupdate.lnm=onerow[1];
                   toupdate.readname+=onerow[1];
                }
              if(onerow[2]){
                 toupdate.emailone=onerow[2].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase();
                if(!EmailValidator.validate(toupdate.emailone)){
                   toupdate.emailone_error=true;
                }
              }
               if(onerow[3]){
                 toupdate.emailtwo=onerow[3].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase();
                if(!EmailValidator.validate(toupdate.emailtwo)){
                   toupdate.emailtwo_error=true;
                }
              }
              if(onerow[4]){
                toupdate.prtphone=onerow[4];
              }
              this.toimport.push(toupdate);
            }
          });

          console.log(this.toimport);
          if(!this.toimport.length){
            return this.$f7.dialog.alert('No data was processed. Please review the selected file. If error persists, contact support.', 'No data received')
          }
          else{
              this.processed=true;
          }
        }).catch(error=>{
          console.log(error);
          this.$f7.dialog.alert(error);
        })

      }
   }
   

   
  }
}
</script>
<style>

</style>