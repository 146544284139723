<template>
  <f7-page name="one_grade">
    <f7-navbar :title="focusedgrade.nam" :subtitle="humandate" back-link="Back">
    </f7-navbar>

<template v-if="!mysettings.targetuser">
  <f7-list inset class="no-margin-top">
     <f7-list-item
        :footer="onlyverified?'Displaying only verified senders':'Displaying all'"
        title="Show only verified senders">
        <f7-toggle :checked="onlyverified" @toggle:change="onlyverified=$event" slot="after"></f7-toggle>
      </f7-list-item>
  </f7-list>
      <f7-block-title class="boldertitl">Students with a cleared health check</f7-block-title>

<f7-list media-list class="no-margin">
      <f7-list-item
        v-for="stu in filteredAllcleareadaaya"
        :key="stu.id"
        :footer="'By '+stu.by+' at '+$moment.unix(stu.cleared).format('HH:mm')"
        :after="stu.skn?('Scan: '+$moment.unix(stu.skn).format('HH:mm')):'Pending scan'"
         :title="stu.tnam">
        <img src="../static/img/default_original.png" slot="media" class="usricon" />
         </f7-list-item>
</f7-list>
      <f7-block v-if="!filteredAllcleareadaaya.length">No cleared students on {{humandate}}</f7-block>

    <f7-block-title class="boldertitl">Students pending a daily health check</f7-block-title>
<f7-list media-list class="no-margin">
      <f7-list-item
        v-for="stu in pendinghealthcahec"
        :key="stu.sid"
        :footer="stu.pasts"
         :title="stu.fnm+' '+stu.lnm">
        <img src="../static/img/default_original.png" slot="media" class="usricon" />
         </f7-list-item>
</f7-list>
      <f7-block v-if="!pendinghealthcahec.length">No pending health checks on {{humandate}}</f7-block>

   <f7-block class="margin-bottom"><f7-block-title class="boldertitl">Absence reports</f7-block-title></f7-block>
      <f7-list class="no-margin-top">
        <f7-list-item
        v-for="stu in filtererAusentes"
        :key="stu.id"
        :footer="'Sent on '+$moment.unix(stu.wh).format('LLL')"
         :title="stu.tnam"
         link="#"
         @click="abrepfocus = stu"
         :after="stu.reason"></f7-list-item>
      </f7-list>
      <f7-block v-if="!filtererAusentes.length">No absences reported on {{humandate}}</f7-block>

    </template>
    <template v-else>
      <f7-block class="margin-bottom"><f7-block-title>Cleared students</f7-block-title></f7-block>
      <f7-list class="no-margin-top">
        <f7-list-item
        v-for="stu in sortedCleared_unverified"
        :key="stu.tsid+'_'+stu.wh"
        :footer="'Completed on '+$moment.unix(stu.wh).format('LLL')"
         :title="stu.xnm"></f7-list-item>
      </f7-list>
      <f7-block v-if="!sortedCleared_unverified.length">No cleared students on {{humandate}}</f7-block>
      <f7-block class="margin-bottom"><f7-block-title>Absence reports</f7-block-title></f7-block>
      <f7-list class="no-margin-top">
        <f7-list-item
        v-for="stu in sortedAbrep"
        :key="stu.stinf.xnm+'_'+stu.wh"
        :footer="'Sent on '+$moment.unix(stu.wh).format('LLL')"
         :title="stu.stinf.xnm"
         link="#"
         @click="abrepfocus = stu"
         :after="stu.reason"></f7-list-item>
      </f7-list>
      <f7-block v-if="!sortedAbrep.length">No absences reported on {{humandate}}</f7-block>
    </template>
<f7-block-title class="boldertitl">Coordinator{{(focusedgrade.stas && Object.keys(focusedgrade.stas).length>1)?'s':''}}</f7-block-title>
<f7-list media-list class="no-margin">
    <f7-list-item v-if="!focusedgrade.stas || !Object.keys(focusedgrade.stas).length" header="email" title="Coordinator not set"></f7-list-item>
  <f7-list-item class="coorli" v-for="(status,uncoordemail) in focusedgrade.stas" :key="uncoordemail" header="email" :title="uncoordemail.split('|').join('.')" :footer="status=='p'?'Has not accessed besafe.school yet':'Account linked OK'" ></f7-list-item>
  <f7-block-footer>Coordinators receive emails with absence reports for this grade.</f7-block-footer>
</f7-list>
    <f7-popup class="abrepdetail" :opened="!!abrepfocus.wh" @popup:closed="abrepfocus = {}">
      <f7-page v-if="abrepfocus.stinf">
        <f7-navbar title="Absence Form">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list media-list class="no-margin ">
            <f7-list-item
            :title="abrepfocus.stinf.xnm || (abrepfocus.stinf.fnm+' '+abrepfocus.stinf.lnm)"
            :footer="abrepfocus.by?abrepfocus.by.split('|').join('.'):'-'"
            >
              <img src="../static/img/default_original.png" slot="media" class="usricon" />
            </f7-list-item>
            <f7-list-item divider title="Details"></f7-list-item>
            <f7-list-item header="Dates" :title="abrepfocus.dates"></f7-list-item>
            <f7-list-item header="Reason" :title="abrepfocus.reason_detail || abrepfocus.reason"></f7-list-item>
            <f7-list-item header="Notes">
              <div slot="subtitle" class="overflow-new-line">{{ abrepfocus.notes || '-' }}</div>
            </f7-list-item>
            <f7-list-item title="Contacts" :after="abrepfocus.exposure"></f7-list-item>
          </f7-list>
          <f7-block>
            <f7-block-title>Symptoms</f7-block-title>
            <f7-block v-if="abrepfocus.symptoms">
               <f7-chip v-for="unsyn in abrepfocus.symptoms" :key="unsyn" :text="unsyn"></f7-chip>
            </f7-block>
            <f7-block v-else>No symptoms reported</f7-block>
          </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['gradeid','gdate'],
  data(){
    return {
      allclear:{},
      allabrep:{},
      allskns:{},
      abrepfocus:{},
      Allcleareadaaya:[],
      pendinghealthcahec: [],
      arrayofausente: [],
      onlyverified:false
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    filtererAusentes(){
      //arrayofausente
      if(this.onlyverified){
        return this.arrayofausente.filter(unstu => {
            return unstu.type=='sid'
        });
      }
      else{
        return this.arrayofausente
      }
    },
    filteredAllcleareadaaya(){
      if(this.onlyverified){
        return this.Allcleareadaaya.filter(unstu => {
            return unstu.type=='sid'
        });
      }
      else{
        return this.Allcleareadaaya
      }
    },
    focusedgrade(){
      return this.mysettings.grades[this.gradeid];
    },
    humandate(){
      return this.$moment(this.gdate,'MM-DD-YYYY').format('LL')
    },
    sortedCleared_unverified(){
      return false;
      let arreglados =  Object.values(this.allclear);
            arreglados.sort((a, b) => {
              const x = a.xnm.toLowerCase();
              const y = b.xnm.toLowerCase();
              if(x > y){
                  return 1
              }
              else if(x < y){
                  return -1
              }
              else{
                 return 0
              }
          });
          console.log('los Cleared',arreglados)
          return arreglados;
    },
    sortedAbrep(){
      let arreglados =  Object.values(this.allabrep);
            arreglados.sort((a, b) => {
              const x = a.stinf.xnm.toLowerCase();
              const y = b.stinf.xnm.toLowerCase();
              if(x > y){
                  return 1
              }
              else if(x < y){
                  return -1
              }
              else{
                 return 0
              }
          });
          return arreglados;
    }
  },
  created(){

  },
  mounted(){
    this.getfreshdata();
  },
  methods:{
    getfreshdata(){
     this.$f7.dialog.preloader('Loading...');
      let ahoramom=this.$moment(this.gdate,'MM-DD-YYYY');
      let ano = ahoramom.format('YYYY');
      let mes = ahoramom.format('MM');
      let dia = ahoramom.format('DD');
      let laref= 'daily/'+this.userinfo.uid+'/bygr/'+this.gradeid+'/'+ano+'/'+mes+'/'+dia;
      let abrepref= 'abrep/'+this.userinfo.uid+'/bygr/'+this.gradeid+'/'+ano+'/'+mes+'/'+dia;
      if(this.mysettings.targetuser){
        laref+='/UNX';
        abrepref+='/UNX';
      }
      else{
         laref+='/VER';
        abrepref+='/VER';
      }
      let promix=[
        this.$firebase.database().ref(laref).once('value'),
        this.$firebase.database().ref(abrepref).once('value')
      ];
      if(!this.mysettings.targetuser){
       promix.push( this.$firebase.database().ref('skns/'+this.userinfo.uid+'/bygr/'+this.gradeid+'/'+ano+'/'+mes+'/'+dia).once('value') );
      }
      return Promise.all(promix).then(snpsho=>{
        let misdaots= snpsho[0].val() || {};
        let misabs= snpsho[1].val() || {};
        console.log(misdaots,misabs);
         if(snpsho[2] && snpsho[2].exists()){
         let mykns= snpsho[2].val() || {};
           this.allskns=Object.assign({},mykns);
            console.log('allskns',this.allskns)
        }
        this.allclear=Object.assign({},misdaots);
        this.allabrep=Object.assign({},misabs);
         console.log('allabrep',this.allabrep)
         this.$f7.dialog.close();
         this.listaDeCleared();
      }).catch(error=>{
        console.log(error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
      });
    },
    listaDeCleared(){
      let allStudentsHere = this.$store.getters.sudentsByGrade(this.gradeid);
      let learedarray = Object.values(this.allclear) || []
      let arrayofAbsecen = Object.values(this.allabrep || {}) || []
      let ausentes={};
      arrayofAbsecen.forEach(unstuabsente => {
        unstuabsente.id = unstuabsente.stinf.tsid || unstuabsente.stinf.sid
        unstuabsente.type = unstuabsente.stinf.tsid?'tsid':'sid'
        unstuabsente.tnam = unstuabsente.stinf.xnm || (unstuabsente.stinf.fnm+' '+unstuabsente.stinf.lnm)
        ausentes[unstuabsente.id] =unstuabsente;
      });
       let allskns = this.allskns || {}
      let allcleared={};
      learedarray.forEach(unstu => {
        if(unstu.sid){
          let elstu = this.$store.getters.oneStudent(unstu.sid) || {};
          allcleared[unstu.sid]={
            tnam: elstu.fnm+' '+elstu.lnm,
            id: unstu.sid,
            cleared: unstu.wh,
            type: 'sid',
            by: unstu.by.split('|').join('.'),
            skn: this.allskns[unstu.sid]?this.allskns[unstu.sid].w : null
          };
        }
        else if(unstu.tsid){
          allcleared[unstu.tsid]={
            tnam: unstu.xnm,
            id: unstu.tsid,
            type: 'tsid',
            cleared: unstu.wh,
             by: unstu.by.split('|').join('.'),
            skn: this.allskns[unstu.tsid]?this.allskns[unstu.tsid].w : null
          };
        }
      });
      let pendinghealthcahec=allStudentsHere.filter(onestuhere => {
          console.log('onestuhere',onestuhere.sid);
          return !allcleared[onestuhere.sid] && !ausentes[onestuhere.sid];
      });
      let neopending = pendinghealthcahec.map(unstu => {
        let parnts = unstu.prtemails || {};
        let prtnskeys =Object.values(parnts) || [];
        let haylinkeads= prtnskeys.some(unrmsil => {
          return unrmsil=='linked'
        });
          unstu.pasts= !haylinkeads?'No parent/tutor accounts linked':'Parent/tutor account linked';
          return unstu
      });
      this.pendinghealthcahec= neopending;
      let arrayofausentes = Object.values(ausentes) || [];
      arrayofausentes.sort((a, b) => {
                    const x = a.tnam.toLowerCase();
                    const y = b.tnam.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                      return 0
                    }
                });
                this.arrayofausente=arrayofausentes;
      let cleareadaaya =Object.values(allcleared) || [];
        cleareadaaya.sort((a, b) => {
                    const x = a.tnam.toLowerCase();
                    const y = b.tnam.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                      return 0
                    }
                });
      this.Allcleareadaaya = cleareadaaya;
    },
  }
}
</script>
<style>
.usricon{
  width: 40px;
  border-radius: 50%;
}
.coorli .item-title{
  font-weight: bold!important;
}
.boldertitl.block-title{
      color: #366d50;
    font-weight: bold;
}
</style>