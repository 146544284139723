<template>
  <f7-page name="students" @page:beforein="loadAllSettings({andstudents:true,  andstaff: true, andvisitors: true})">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="My institution" subtitle="Class Registry and Staff"></f7-nav-title>
       <f7-nav-right>
        <f7-link href="/institution/" icon-material="tune"></f7-link>
    </f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-block-title v-if="!mysettings.targetuser">Optional: create students entities to account for daily checks and absences</f7-block-title>

    <f7-list class="liboldtitle margin-top-half no-margin-bottom" media-list>
      <f7-list-item divider title="Grades/Levels"></f7-list-item>
      <f7-list-item
       v-for="grade in orderedActivegrades"
      :key="grade.gid"
      header="Grade/level"
      :title="grade.nam"
      :footer="'Coordinator: '+((!grade.stas || !Object.keys(grade.stas).length)?'not set':Object.keys(grade.stas).map(one=> one.split('|').join('.')).join(', '))"
      :link="'/grade/'+grade.gid"
      >
      <f7-icon v-if="!mysettings.targetuser" slot="after"  material="group" >
          <f7-badge class="sudobage" :color="grade.count?'teal':'gray'">{{grade.count}}</f7-badge>
        </f7-icon>
      </f7-list-item>
       <f7-block-footer v-if="!mysettings.targetuser" class="no-margin-bottom">
      <p>You can create students and link them to users or their parents accounts.</p>
    </f7-block-footer>
    </f7-list>
    <f7-list v-if="!mysettings.targetuser && !mysettings.nofaculty" class="liboldtitle margin-top-half" media-list>
       <f7-list-item divider title="Staff Faculty"></f7-list-item>
       <f7-list-item link="/allmystaff/"  title="All My Staff">
         <f7-icon slot="after"  material="group" >
          <f7-badge class="sudobage" :color="howmanysaff?'teal':'gray'">{{howmanysaff}}</f7-badge>
        </f7-icon>
       </f7-list-item>
    </f7-list>
    <f7-block v-else><br/></f7-block>

    <f7-list class="liboldtitle margin-top-half" media-list>
       <f7-list-item divider title="Visitors"></f7-list-item>
       <f7-list-item link="/visitors/"  title="All My Visitors">
         <f7-icon slot="after"  material="group" >
          <f7-badge class="sudobage" :color="howManyVisitors?'teal':'gray'">{{howManyVisitors}}</f7-badge>
        </f7-icon>
       </f7-list-item>
    </f7-list>




  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';

export default {
    mixins: [loadsettings],
  data (){
    return {
      grades:{}
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings','staff', 'visitors']),
    ...mapGetters(['orderedActivegrades']),
    howmanysaff(){
        return Object.values(this.staff).filter(staff=>!staff.inkt).length
    },

    howManyVisitors() {
      return Object.values(this.visitors).length;
    }
  },
  mounted(){

  },
  created(){

  },
  methods:{




  }
}
</script>
<style>
.liboldtitle .item-title{
  font-weight: bold!important;
}
.sudobage{
  left: 23px!important;
    top: -9px!important;
    font-weight: bold;
    height: 18px;
    width: 20px;
    padding: 0px 13px;
    font-size: 12px;
}
</style>