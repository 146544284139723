<template>
    <f7-app :params="f7params">

        <!-- Left panel with cover effect when hidden -->
        <f7-panel left cover :visible-breakpoint="960">
            <f7-view>
                <f7-page>
                    <f7-navbar :title="myinfo.schoolname?myinfo.schoolname:'BSF Admin'" :subtitle="myinfo.schoolname?'Besafe.school admin':''"></f7-navbar>
                    <f7-block class="text-align-center margin-half">
                        <img class="menucolelogo" :src="myinfo.logo || '../static/img/default_logo.png'"/>
                    </f7-block>
        

                    <f7-block v-if="canImpersonate">
                        <p class="text-align-center" style="color: #828282">
                            You're able to impersonate as <b>{{ schoolname }}</b>
                        </p>
                        <impersonate-button></impersonate-button>
                    </f7-block>

                    <f7-block-title class="margin-top-half">Menu</f7-block-title>
        
                    <f7-list class="linksmenu no-margin-top">
                        <f7-list-item
                            v-for="onemenu in menuToShow"
                            :key="onemenu.url"
                            @click="setactiveTab(onemenu.url)" v-bind:class="{active:activeTab==onemenu.url}" :footer="onemenu.footer" link="#" :title="onemenu.title" view=".view-main" panel-close
                        >
                            <f7-icon slot="media" :material="onemenu.icono"></f7-icon>
                        </f7-list-item>
                    </f7-list>

                    <f7-block>
                        <f7-button outline @click="logout()" raised>Logout</f7-button>
                        <p class="sogris"> BSF Admin V <span class="myver">{{appVersion}}</span> </p>
                    </f7-block>
                </f7-page>
            </f7-view>
        </f7-panel>

        <!-- Right panel with reveal effect-->
        <f7-panel right reveal theme-dark>
            <f7-view>
                <f7-page>
                    <f7-navbar title="Right Panel"></f7-navbar>
                    <f7-block>Right panel content goes here</f7-block>
                </f7-page>
            </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" url="/"></f7-view>

        <!-- Popup -->
        <f7-popup id="my-popup">
            <f7-view>
                <f7-page>
                    <f7-navbar title="Popup">
                        <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block>
                        <p>Popup content goes here.</p>
                    </f7-block>
                </f7-page>
            </f7-view>
        </f7-popup>

        <f7-login-screen opened id="my-login-screen">
            <f7-view>
                <f7-page login-screen>
                    <f7-login-screen-title>Admin<br/><small>besafe.school</small></f7-login-screen-title>
                    <f7-block>
                        <f7-button @click="alertLoginData" fill raised>Login with Google</f7-button>
                    </f7-block>
                    <f7-list>
                        <f7-block-footer>
                            besafe.school &copy; 2020 <br>Admin Dashboard V {{appVersion}}
                        </f7-block-footer>
                    </f7-list>
                </f7-page>
            </f7-view>
        </f7-login-screen>
    </f7-app>
</template>

<script>
import routes from '../js/routes.js';
import { mapState } from 'vuex';

import ImpersonateButton from '../components/impersonate/ImpersonateButton.vue';

export default {
    data() {
        return {
            // Framework7 Parameters
            f7params: {
                name: 'BSF Admin', // App name
                theme: 'md', // Automatic theme detection
                dialog: {
                    destroyOnClose: true,
                },
                // App routes
                routes: routes,
            },
            menu: [
                //admin_panel_settings
                {
                    url: '/hicapps/',
                    footer: 'Gestión cuentas',
                    title:'HICAPPS',
                    icono:'language',
                    fgonly: true
                },
                {
                    url: '/',
                    footer: '& subscription',
                    title:'Account',
                    icono:'account_circle'
                },
                {
                    url: '/settings/',
                    footer: 'System Preferences',
                    title:'Settings',
                    icono:'tune'
                },
                {
                    url: '/questions/',
                    footer: 'Customize questions',
                    title:'Forms',
                    icono:'live_help'
                },
                {
                    url: '/students/',
                    footer: 'Class registry',
                    title:'My Institution',
                    icono:'people'
                },
                {
                    url: '/importme/',
                    footer: 'Students .xlsx',
                    title:'Data import',
                    icono:'cloud_upload',
                    authonly: true
                },
                {
                    url: '/dashboard/',
                    footer: 'Realtime info',
                    title:'Dashboard',
                    icono:'multiline_chart'
                },
                // {
                //   url: '/notifix/',
                //   footer: 'Contact parents & staff',
                //   title:'Notifications',
                //   icono:'sms_failed',
                //    authonly: true
                // },
                {
                    url: '/exportme/',
                    footer: 'Download your data',
                    title:'Data export',
                    icono:'cloud_download',
                    authonly: true
                },
                {
                    url: '/reports/',
                    footer: "Students and Staff",
                    title:'Search Status',
                    icono:'search',
                    authonly: true
                }
            ],
            canImpersonate: false,
            schoolname: ''
        }
    },

    computed:{
        ...mapState(['appVersion','activeTab','myinfo','mysettings']),

        menuToShow(){
            let menu=this.menu || [];
            let filtered = menu.filter(unmenu =>{
                return (!unmenu.fgonly || this.myinfo.fd) && (!unmenu.authonly || !this.mysettings.targetuser)
            });
            return filtered;
        }
    },

    components: {
        ImpersonateButton
    },

    methods: {
        logout() {
            return this.$firebase.auth().signOut();
        },

        alertLoginData() {
            this.$f7.dialog.preloader('Logging in...');
            var provider = new this.$firebase.auth.GoogleAuthProvider();
            this.$firebase.auth().signInWithRedirect(provider);
        },

        setactiveTab(activeTab) {
            console.log('setting ctive tav',activeTab);
            this.$store.commit('setWhatTo',{what:'activeTab',to:activeTab});
            this.$f7.views.main.router.navigate(activeTab);
        },

        checkTimeZone(settings){
            if(!settings.tz){
                console.log('no tengo timezone, detectar y grabar')
                let mytimeon= this.$moment.tz.guess();
                if(mytimeon){
                    console.log('detectando mi timezone',mytimeon, 'grabando');
                    this.$firebase.database().ref('settings/'+this.$firebase.auth().currentUser.uid+'/tz').set(mytimeon).then(()=>{
                        console.log('update ahora settings')
                        this.$store.commit('updateCoreSetting',{what:'tz',to:mytimeon});
                    }).catch(error=>{
                        console.log('error saving timezone',error);
                    });
                }
            }
        },

        checkIfCanImpersonate: function() {
            const currentUserEmail = this.$firebase.auth().currentUser.email.replaceAll('.', '|');

            this.$firebase.database().ref(`/imp/${currentUserEmail}`).once('value', snapshot => {
                if (snapshot.exists()) {
                  this.canImpersonate = true;

                  const adminUid = snapshot.val().uid;

                  this.$firebase.database().ref(`/myinfo/${adminUid}/schoolname`).once('value', snapshot => {
                    this.schoolname = snapshot.val();
                  }).catch(error => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert('Error.' + error);
                  });
                }
            }).catch(error => {
              this.$f7.dialog.close();
              this.$f7.dialog.alert('Error.' + error);
            });
        }
    },

    mounted() {
        this.$f7ready((f7) => {
            this.$f7.dialog.preloader('Loading...');

            // Call F7 APIs here
            this.$firebase.auth().onAuthStateChanged((user)=> {
                if (user) {
                    if(user.isAnonymous){
                        this.$f7.loginScreen.open('#my-login-screen');
                        this.$f7.dialog.close();
                        return this.$firebase.auth().signOut();
                    } else {
                        let promisers=[
                            this.$firebase.database().ref('myinfo/'+user.uid).once('value'),
                            this.$firebase.database().ref('paypal/'+user.uid).once('value'),
                            this.$firebase.database().ref('suscriptions/'+user.uid).once('value'),
                            this.$firebase.database().ref('settings/'+user.uid).once('value')
                        ];
                        //pedir megameta
                        return Promise.all(promisers).then(multisnaps=>{
                            // User is signed in.
                            let myinfo=multisnaps[0].val() || {};
                            let hispaymnts=multisnaps[1].val() || {};
                            let suscriptions =multisnaps[2].val() || '';
                            let settings =multisnaps[3].val() || {};
                            this.$store.commit('setObjTo',{what:'myinfo',to:myinfo});
                            this.$store.commit('setObjTo',{what:'paypal',to:hispaymnts});
                            this.$store.commit('setWhatTo',{what:'suscriptions',to:suscriptions});
                            this.$store.commit('setObjTo',{what:'mysettings',to:settings});
                            // this.$f7.views.main.router.navigate(this.$store.state.whereTo);
                            let userinfo = {
                                displayName: user.displayName,
                                email: user.email,
                                emailVerified: user.emailVerified,
                                isAnonymous: user.isAnonymous,
                                phoneNumber: user.phoneNumber,
                                uid: user.uid
                            };
                            this.$store.commit('setObjTo',{what:'userinfo',to:userinfo});
                            console.log('welcome', user, myinfo, hispaymnts, suscriptions)
                            this.$f7.dialog.close();
                            this.$f7.loginScreen.close();
                            this.checkTimeZone(settings);
                            this.checkIfCanImpersonate();
                        }).catch(error=>{
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(error.message,error.code);
                        })
                    }
                } else {
                    console.log('no user logged in');
                    this.$f7.dialog.close();
                    this.$f7.loginScreen.open('#my-login-screen');
                }
            });
        });
    }
}
</script>

<style>
.linksmenu .active .item-link {
    border-left: 5px solid #0f4f0e;
    background: #fff;
    color: #0f4f0e;
}

.linksmenu .active .item-link .icon {
    color: #0f4f0e!important;
}

.linksmenu .item-link .icon {
    color: #828282;
}

.linksmenu .active .item-link .item-footer {
    color: #5a5a5a!important;
}

.linksmenu .icon.material-icons, .margin0auto {
    margin: 0 auto!important;
}

.linksmenu .active .item-title {
    font-weight: bold!important;
}

.view-main .page-content {
    background: #F1EFEF!important;
}

.profile .item-media .icon {
    margin: 0 auto!important;
}

.cardheaderflex {
    display: block!important;
    padding: 0px!important;
}

.bold {
    font-weight: bold;
}

.menucolelogo {
    height:40px;
}
</style>