<template>
  <f7-page name="reportdetail" @page:beforein="attachObservers()" @page:beforeout="destroyObservers()" >
    <f7-navbar :title="(focusedStaff.fnm+' '+focusedStaff.lnm)" subtitle="Staff" back-link="Back"></f7-navbar>


<f7-block-title>Latest reports</f7-block-title>
<f7-list media-list>
  <f7-list-item
  v-for="report in orederdReps"
  :key="report.wh"
  :title="report.reason?'Absence Report':'Cleared'"
  :subtitle="report.dat"
  :footer="report.by"
  :link="report.reason?'#':null"
  @click="report.reason?(abrepfocus = report):null"
  ></f7-list-item>
</f7-list>

 <f7-popup class="abrepdetail" :opened="!!abrepfocus.reason" @popup:closed="abrepfocus = {}">
      <f7-page v-if="abrepfocus.reason">
        <f7-navbar title="Absence Form">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list media-list class="no-margin ">
            <f7-list-item divider title="Details"></f7-list-item>
            <f7-list-item header="Dates" :title="abrepfocus.dates"></f7-list-item>
            <f7-list-item header="Reason" :title="abrepfocus.reason_detail || abrepfocus.reason"></f7-list-item>
            <f7-list-item header="Notes">
              <div slot="subtitle" class="overflow-new-line">{{ abrepfocus.notes || '-' }}</div>
            </f7-list-item>
            <f7-list-item title="Contacts" :after="abrepfocus.exposure"></f7-list-item>
          </f7-list>
          <f7-block>
            <f7-block-title>Symptoms</f7-block-title>
            <f7-block v-if="abrepfocus.symptoms">
               <f7-chip v-for="unsyn in abrepfocus.symptoms" :key="unsyn" :text="unsyn"></f7-chip>
            </f7-block>
            <f7-block v-else>No symptoms reported</f7-block>
          </f7-block>
      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['staffid'],
  data(){
    return {
     reports: [],
     dailyclearance: null,
     abrepdailyobs: null,
       abrepfocus:{},
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    focusedStaff(){
      return this.$store.getters.oneStaff(this.staffid)
    },
    orederdReps(){
      return this.reports.sort((a, b)=> {
          return b.wh - a.wh;
        });
    }
  },
  created(){
    console.log(this.focusedStaff)
  },
  methods:{
    attachObservers(){
      console.log('encender observables')
       this.dailyclearance = this.$firebase.database().ref('daily/'+this.userinfo.uid+'/bysta/'+this.staffid);
      this.dailyclearance.on('child_added', (data)=> {
        console.log('dailyclearance',data.key,data.val())
        let add=data.val();
        add.dat=this.$moment.unix(add.wh).format('LLL')
        add.by=this.staffid.split('|').join('.')
        this.reports.push( add )
      });


      this.abrepdailyobs = this.$firebase.database().ref('abrep/'+this.userinfo.uid+'/bysta/'+this.staffid);
        this.abrepdailyobs.on('child_added', (data)=> {
        console.log('abrepdailyobs',data.key,data.val())
        let add=data.val();
        add.dat=this.$moment.unix(add.wh).format('LLL')
        add.by=add.by?add.by.split('|').join('.'):add.by
        this.reports.push( add )
      });

    },
    destroyObservers(){
      console.log('desturyendo observables');
      if(this.dailyclearance){
        this.dailyclearance.off();
      }
      if(this.abrepdailyobs){
        this.abrepdailyobs.off()
      }
    },






  }
}
</script>
<style>

</style>