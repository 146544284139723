<template>
  <f7-page name="newstaff">
    <f7-navbar title="Create new staff/factuly" back-link="Back"></f7-navbar>
    <f7-block-title>Add new faculty/staff</f7-block-title>

 <f7-list class="no-margin" no-hairlines-md>
       <f7-list-input
        label="email*"
        type="email"
        placeholder="staff@yourschool.com"
         :value="leemail"
        @input="leemail = $event.target.value"
        clear-button
        required
        validate
      >
      </f7-list-input>
      
      <f7-list-input
        label="First name *"
        type="text"
        placeholder="First name"
         :value="neo_firstname"
        @input="neo_firstname = $event.target.value"
        clear-button
        required
       validate
      >
      </f7-list-input>

       <f7-list-input
        label="Last name *"
        type="text"
        placeholder="Last name"
         :value="neo_lastname"
          @input="neo_lastname = $event.target.value"
        clear-button
        required
        validate
      >
      </f7-list-input>
       <f7-list-input
        label="Phone"
        type="tel"
         :value="lemobile"
        @input="lemobile = $event.target.value"
        placeholder="Parent's mobile phone"
        clear-button
      >
      <span slot="info">If present, you will be able to text them relevant information</span>
      </f7-list-input>
      </f7-list>

<f7-block>
    <f7-button fill @click="createNewStaff()" color="teal" large icon-material="person_add">Create</f7-button>
</f7-block>




  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['precreate','gradeid'],
  data(){
    return {
      neo_firstname:'',
      neo_lastname:'',
      lemobile: '+1',
      leemail: '',
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
  },
  created(){
   if(this.precreate!='0' && this.gradeid!='0'){
     console.log('ambos pasan')
     this.leemail=this.precreate.split('|').join('.');
   }
  },
  methods:{
    createNewStaff(){
      if(!this.neo_firstname || !this.neo_lastname || !this.leemail){
        return this.$f7.dialog.alert('Email and names are required','Missing data');
      }
      else{
         let cleanemail=this.leemail.split('.').join('|').toLowerCase();
          let toupdate = {
            fnm: this.neo_firstname.trim(),
            lnm: this.neo_lastname.trim(),
            tfid: cleanemail,
            leemail: this.leemail.toLowerCase(),
            lemobile: (this.lemobile && this.lemobile!='+1')?this.lemobile : null,
            inkt:null
          };
          this.$f7.dialog.preloader('Creating...');
          let updates = {};
           updates['staff/'+this.userinfo.uid+'/'+cleanemail] = toupdate;
           if(this.precreate!='0' && this.gradeid!='0'){
            updates['localprivs/'+this.userinfo.uid+'/'+cleanemail+'/stas/'+this.gradeid] = true;
            updates['settings/'+this.userinfo.uid+'/grades/'+this.gradeid+'/stas/'+cleanemail] = 'p';
          }

          return this.$firebase.database().ref().update(updates).then(()=>{
              this.$f7.dialog.close();
              this.$store.commit('updateStaff',{tfid:cleanemail,to:toupdate});
              if(this.precreate!='0' && this.gradeid!='0'){
                 let pregradeinfo=Object.assign({},this.mysettings.grades[this.gradeid]);
                if(!pregradeinfo.stas){
                  pregradeinfo.stas={};
                }
                pregradeinfo.stas[cleanemail]='p';
                this.$store.commit('updateGrades',{gid:this.gradeid,to:pregradeinfo});
              }
              this.$f7.views.main.router.back();
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      }
    }
  }
}
</script>
<style>

</style>