<template>
  <f7-card>
  <f7-card-header class="cardheaderflex">
       <f7-list class="liboldtitle no-margin-top no-margin-bottom" media-list>
      <f7-list-item
      header="Grade/level"
      title="My Staff"
      >
     <f7-icon slot="after"  material="group" >
          <f7-badge class="sudobage" :color="howmanystaff?'teal':'gray'">{{howmanystaff}}</f7-badge>
        </f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-card-header>
  <f7-card-content>
    <f7-list :class="loading?'skeleton-text skeleton-effect-blink':null" class="fixic datalistxd">
      <f7-list-item
      title="Staff cleared"
      footer="Daily checks completed"
      :after="cleared || '0'"
      >
       <f7-icon color="primary" material="assignment_turned_in" slot="media"></f7-icon>
      </f7-list-item>
       <f7-list-item
      title="Admission scans"
      footer="Not available on anonymous mode"
      :after="skns || '0'"
      >
      <f7-icon material="important_devices" slot="media"></f7-icon>
      </f7-list-item>

        <f7-list-item
      title="Absence forms"
      footer="received today"
      :after="abrep || '0'"
      >
       <f7-icon color="orange" material="event_busy" slot="media"></f7-icon>
      </f7-list-item>
    </f7-list>
    </f7-card-content>
  <f7-card-footer>
    <f7-link></f7-link>
    <f7-button :href="'/staffdailydata/'+fechforlink" outline>Details</f7-button>
  </f7-card-footer>
</f7-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props:['fechafocus'],
  data(){
    return {
      loading: true,
      cleared: 0,
      abrep: 0,
      skns: 0
    }
  },
  computed:{
    ...mapState(['mysettings','userinfo','staff']),
    fechforlink(){
      return this.fechafocus.split('/').join('-');
    },
    howmanystaff(){
      return Object.values(this.staff).filter(staff=>!staff.inkt).length
    }
  },
  watch:{
    fechafocus(){
      console.log('dtchgh')
      this.getmyinfo();
    }
  },
  mounted(){
    this.getmyinfo();
  },
  methods:{
    getmyinfo(){
      this.loading=true;
      let ahoramom=this.$moment(this.fechafocus,'MM/DD/YYYY');
      let ano = ahoramom.format('YYYY');
      let mes = ahoramom.format('MM');
      let dia = ahoramom.format('DD');
      //ahora scans, si es que es auth
      let promix=[
        this.$firebase.database().ref('daily/'+this.userinfo.uid+'/bystadaily/'+ano+'/'+mes+'/'+dia).once('value'),
        this.$firebase.database().ref('abrep/'+this.userinfo.uid+'/bystadaily/'+ano+'/'+mes+'/'+dia).once('value'),
        this.$firebase.database().ref('skns/'+this.userinfo.uid+'/staff/'+ano+'/'+mes+'/'+dia).once('value')
      ];
      return Promise.all(promix).then(snpsho=>{
        let misdaots= snpsho[0].val() || {};
        let misabs= snpsho[1].val() || {};
         let mykns= snpsho[2].val() || {};
          this.skns = Object.keys(mykns).length;
        this.cleared=Object.keys(misdaots).length;
         this.abrep=Object.keys(misabs).length;
         console.log('STAFF skns',mykns)
        this.loading=false;
      }).catch(error=>{
        console.log(error);
         this.loading=false;
      });
    }
  }
}
</script>
<style>
.datalistxd .item-after span{
  font-size: 18px;
    color: #000;
    font-weight: bold;
}
</style> 