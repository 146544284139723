<template>
  <f7-page name="exportme">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Data Export" subtitle="Download all your data"></f7-nav-title>
    </f7-navbar>


<f7-block-title>Choose the data sources to download</f7-block-title>
<f7-list no-hairlines-md>
    <f7-list-input
        label="Date of report"
        type="datepicker"
        @change="cambiandofecha($event.target.value)"
        :calendar-params="{dateFormat: 'mm-dd-yyyy', value:[today], closeOnSelect: true, disabled: {
        from: today
        }}"
        readonly
      >
      </f7-list-input>
 <f7-list-input
    label="Report format"
    type="select"
    defaultValue="daily"
    placeholder="Please choose..."
     :value="repid"
    @input="repid = $event.target.value"
  >
  <option value="daily">Daily Status</option>

  </f7-list-input>

   <f7-list-input
    label="Grade or staff"
    type="select"
    defaultValue=""
    placeholder="Please choose..."
     :value="targetGradeId"
    @input="targetGradeId = $event.target.value"
  >
  <option value="">Please choose</option>
  <option value="staff">All my staff</option>
  <option value="allgrades">All grades</option>
  <option v-if="mysettings.visitors" value="visitors">All visitors</option>
  <option v-for="grade in orderedActivegrades"  :key="grade.gid" :value="grade.gid">{{grade.nam}}</option>
  </f7-list-input>

   <f7-list-input
    v-if="targetGradeId!=='staff'"
    label="Source"
    type="select"
    defaultValue="verified"
    placeholder="Please choose..."
     :value="source"
    @input="source = $event.target.value"
  >
  <option value="ver">Only verified senders</option>
  <option value="all">Verified and unverified senders</option>

  </f7-list-input>

</f7-list>
<f7-block><f7-button outline @click="startdownload()">Download XLSX</f7-button></f7-block>

  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data (){
    return {
     repid:'daily',
     targetGradeId:'',
     source:'ver',
      fechaselect: this.$moment().format('MM-DD-YYYY'),
      today: new Date,
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings','myinfo']),
  ...mapGetters(['orderedActivegrades']),


  },
  mounted(){

  },
  created(){

  },
  methods:{
     cambiandofecha(valorx){
      console.log('nueva fecha',valorx)
      this.fechaselect = valorx
    },
   startdownload(){
     if(!this.repid ||!this.targetGradeId || !this.source ){
      return this.$f7.dialog.alert('Please specify all fields','Missing data');
     }
     console.log('descargando',this.repid, this.targetGradeId, this.source, this.fechaselect)
      this.$f7.dialog.preloader('Generating xlsx...');
      this.$f7.toast.show({
          text: 'Starting download',
          position: 'bottom',
          closeTimeout: 3500,
          destroyOnClose: true
      });
      return this.$firebase.auth().currentUser.getIdToken().then((token) => {
             window.location.href = 'https://us-central1-besafe-core.cloudfunctions.net/exportmex/daily/'+this.myinfo.domain+'/'+this.targetGradeId+'/'+this.fechaselect+'/'+this.source+'/'+token;

            /* window.location.href = 'http://localhost:5000/besafe-core/us-central1/exportmex/daily/'+this.myinfo.domain+'/'+this.targetGradeId+'/'+this.fechaselect+'/'+this.source+'/'+token; */
              this.$f7.dialog.close();
          }).catch((error)=> {
          this.$f7.dialog.close();
          console.log("error save: " + error.message);
            this.$f7.dialog.alert(error.message,'Error');
      });
   }
  }
}
</script>
<style>

</style>