import {mapState, mapGetters} from 'vuex'

export const loadsettings = {
    data () {
        return {

        };
      },
      computed:{
        useruid(){
          return this.$store.state.userinfo.uid;
        },
        ...mapState(['students','staff', 'visitors']),

      },

    methods:{
      loadAllSettings(metaextras){
        return new Promise((resolve,reject)=>{
          let posx={};
          let moremap={};
          if(!metaextras.nopreloader){
            this.$f7.dialog.preloader('Loading...');
          }
          let promexser = [this.$firebase.database().ref('settings/'+this.useruid).once('value')];
          if(metaextras.andstudents && !Object.keys(this.students).length){
            posx.andstudents=promexser.length;
            promexser.push(this.$firebase.database().ref('students/'+this.useruid).once('value'));
          }
          if(metaextras.andstaff && !Object.keys(this.staff).length){
            posx.andstaff=promexser.length;
            promexser.push(this.$firebase.database().ref('staff/'+this.useruid).once('value'));
          }
          // traer también los visitors si se mandó como argumento
          if (metaextras.andvisitors && !Object.keys(this.visitors).length) {
            posx.andvisitors = promexser.length;
            promexser.push(this.$firebase.database().ref(`visitors/${this.useruid}`).once("value"))
          }
          return Promise.all(promexser).then((snshot)=>{
            let settings=snshot[0].val() || {};
              console.log(settings);
            this.$store.commit('setObjTo',{what:'mysettings',to:settings});
            if(posx.andstudents!=null){
                if(snshot[posx.andstudents] && snshot[posx.andstudents].exists()){
                  this.$store.commit('setObjTo',{what:'students',to:snshot[posx.andstudents].val()});
                }
              }
              if(posx.andstaff!=null){
                if(snshot[posx.andstaff] && snshot[posx.andstaff].exists()){
                  this.$store.commit('setObjTo',{what:'staff',to:snshot[posx.andstaff].val()});
                }
              }
            if (posx.andvisitors!=null) {
              if (snshot[posx.andvisitors] && snshot[posx.andvisitors].exists()) {
                this.$store.commit('setObjTo', {what: 'visitors', to: snshot[posx.andvisitors].val()});
              }
            }
            let moreRequests=[];
            if(!settings.grades){
              console.log('pidiendo grades');
              moremap.grades=moreRequests.length;
              moreRequests.push(this.$firebase.database().ref('default/grades').once('value'))
            }
            if(!settings.qstns){
              console.log('pidiendo questions');
              moremap.qstns=moreRequests.length;
              moreRequests.push(this.$firebase.database().ref('qstns/').once('value'));
            }
            return Promise.all(moreRequests);
          }).then(multisnapxs=>{
            let updates = {};
            if(moremap.grades!=null){
              console.log('grabando grades');
              let pregrades=multisnapxs[moremap.grades].val() || {};
              updates['settings/'+this.useruid+'/grades'] = pregrades;
              this.$store.commit('updateAllGrades',pregrades);
            }
            if(moremap.qstns!=null){
              console.log('grabando questions');
              let preqstns=multisnapxs[moremap.qstns].val() || {};
              updates['settings/'+this.useruid+'/qstns'] = preqstns;
              this.$store.commit('updateAllQuestions',preqstns);
            }
            return this.$firebase.database().ref().update(updates)
          }).then(()=>{
            if(!metaextras.nopreloader){
              this.$f7.dialog.close();
            }
            resolve(true)
          }).catch(error=>{
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,'Error');
            reject(error)
          });
        });

     }
    }
}