// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
import 'typeface-montserrat';
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
firebase.initializeApp({
  apiKey: "AIzaSyAcOW3ELch796QEiWskQOXQRh5Dc7ckV5g",
  authDomain: "besafe-core.firebaseapp.com",
  databaseURL: "https://besafe-core.firebaseio.com",
  projectId: "besafe-core",
  storageBucket: "besafe-core.appspot.com",
  messagingSenderId: "514794056281",
  appId: "1:514794056281:web:b4001882f373e9a0a71f48",
  measurementId: "G-HMXJPTYD5W"
});
const defaultAnalytics = firebase.analytics();
/* firebase.functions().useFunctionsEmulator('http://localhost:5001'); */
Vue.prototype.$firebase = firebase;
import moment from 'moment-timezone';
moment.locale('en');
Vue.prototype.$moment = moment;
// Import App Component
import App from '../components/app.vue';
import { store } from '../store/store';
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  // Register App Component
  components: {
    app: App
  },
});