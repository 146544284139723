<template>
  <f7-page name="hicapps" @page:beforein="loadAccounts()" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>Activaciones Pendientes</f7-nav-title>
    </f7-navbar>
    <!-- Page content-->
    <f7-toolbar tabbar bottom>
    <f7-link tab-link="#freetrials" tab-link-active >Free trials ({{freeTrialsRequests.length}})</f7-link>
    <f7-link tab-link="#cuentas">Accounts ({{startSubscription.length}})</f7-link>
    <f7-link tab-link="#testing">Testing ({{testingAccounts.length}})</f7-link>
    <f7-link tab-link="#liveprod">Live ({{liveprod.length}})</f7-link>
  </f7-toolbar>
  <f7-tabs animated>
    <f7-tab id="freetrials" class="page-content" tab-active>
      <f7-list class="no-margin" media-list>
        <f7-list-item v-for="account in freeTrialsRequests" :link="'/account/'+account.key" :key="account.key" :title="account.schoolname" :footer="$moment(account.ft.rq).fromNow()" :header="account.domain"></f7-list-item>
      </f7-list>
    </f7-tab>
    <f7-tab id="cuentas" class="page-content">
       <f7-list class="no-margin" media-list>
        <f7-list-item v-for="account in startSubscription" :link="'/account/'+account.key" :key="account.key" :title="account.schoolname" :footer="account.key" :header="account.domain"></f7-list-item>
      </f7-list>
    </f7-tab>
    <f7-tab id="testing" class="page-content">
      <f7-list class="no-margin" media-list>
        <f7-list-item v-for="account in testingAccounts" :link="'/account/'+account.key" :key="account.key" :title="account.schoolname" :footer="account.key" :header="account.domain"></f7-list-item>
      </f7-list>
    </f7-tab>
     <f7-tab id="liveprod" class="page-content">
      <f7-list class="no-margin" media-list>
        <f7-list-item v-for="account in liveprod" :link="'/account/'+account.key" :key="account.key" :title="account.schoolname" :footer="account.key" :header="account.domain"></f7-list-item>
      </f7-list>
    </f7-tab>
  </f7-tabs>


    
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data (){
    return {

    }
  },
   computed: {
   ...mapState(['userinfo','allAccounts']),
    // ...mapGetters(['']),
    testingAccounts(){
      let allctons = Object.values(this.allAccounts) || [];
      return allctons.filter(unaccunt => {
        return !unaccunt.subs && !unaccunt.status && !unaccunt.ft
      });
    },
    freeTrialsRequests(){
      let allctons = Object.values(this.allAccounts) || [];
      return allctons.filter(unaccunt => {
        let ftinfo = unaccunt.ft || {};//ft.rq
        return !unaccunt.subs && !unaccunt.status && ftinfo.rq && !ftinfo.vt
      });
    },
    liveprod(){
         let allctons = Object.values(this.allAccounts) || [];
        return allctons.filter(unaccunt => {
          return unaccunt.status
        });
    },
    startSubscription(){
      let allctons = Object.values(this.allAccounts) || [];
      return allctons.filter(unaccunt => {
          let suscripciones=unaccunt.subs || {};
          let anysuscrpactive = Object.values(suscripciones).some(suscr => {
            return suscr.meta.status=='ACTIVE'
          });
        return anysuscrpactive && !unaccunt.status;
      });
    }
  },
  mounted(){
    console.log(this.userinfo);
  },
  created(){
    
  },
  methods:{
    loadAccounts(){
       let accuts={};
        let statusprom=[];
          let pedidos=[];
       this.$f7.dialog.preloader('Loading...');
       this.$firebase.database().ref('myinfo').once('value').then((snshot)=>{
         
         
         
          snshot.forEach(childsnap => {
            let valors = childsnap.val();
            valors.key = childsnap.key;
            accuts[childsnap.key] = valors;
            if(valors.domain){
              pedidos.push(valors.key);
              statusprom.push( this.$firebase.database().ref('domains/'+valors.domain).once('value') );
            }
          });

          return Promise.all(statusprom);
        }).then(multisnap=>{
          multisnap.forEach((unpostna,idx) => {
            if(unpostna.exists()){
              accuts[pedidos[idx]].domaindata=unpostna.val();
            }
          });
           this.$f7.dialog.close();
          //setObjTo
          this.$store.commit('setObjTo',{what:'allAccounts',to:accuts});
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
    }
  }
}
</script>
<style>

</style>