<template>
  <f7-page name="staffdailydata">
    <f7-navbar title="All my staff" :subtitle="humandate" back-link="Back">
    </f7-navbar>

<template v-if="!mysettings.targetuser">
      <f7-block-title class="boldertitl">Staff with a cleared health check</f7-block-title>

<f7-list media-list class="no-margin">
      <f7-list-item
        v-for="stu in Allcleareadaaya"
        :key="stu.id"
        :after="stu.skn?('Scan: '+$moment.unix(stu.skn).format('HH:mm')):'Pending scan'"
         :title="stu.tnam">
        <img src="../static/img/default_original.png" slot="media" class="usricon" />
         </f7-list-item>
</f7-list>
      <f7-block v-if="!Allcleareadaaya.length">No cleared staff on {{humandate}}</f7-block>

    <f7-block-title class="boldertitl">Staff pending a daily health check</f7-block-title>
<f7-list media-list class="no-margin">
      <f7-list-item
        v-for="stu in pendinghealthcahec"
        :key="stu.sid"
        :footer="stu.pasts"
         :title="stu.fnm+' '+stu.lnm">
        <img src="../static/img/default_original.png" slot="media" class="usricon" />
         </f7-list-item>
</f7-list>
      <f7-block v-if="!pendinghealthcahec.length">No pending health checks on {{humandate}}</f7-block>

   <f7-block class="margin-bottom"><f7-block-title class="boldertitl">Absence reports</f7-block-title></f7-block>
      <f7-list class="no-margin-top">
        <f7-list-item
        v-for="stu in arrayofausente"
        :key="stu.id"
        :footer="'Sent on '+$moment.unix(stu.wh).format('LLL')"
         :title="stu.tnam"
         link="#"
         @click="abrepfocus = stu"
         :after="stu.reason"></f7-list-item>
      </f7-list>
      <f7-block v-if="!arrayofausente.length">No absences reported on {{humandate}}</f7-block>

    </template>

    <f7-popup class="abrepdetail" :opened="!!abrepfocus.wh" @popup:closed="abrepfocus = {}">
      <f7-page v-if="abrepfocus.wh">
        <f7-navbar title="Absence Form">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list media-list class="no-margin ">
            <f7-list-item
            :title="abrepfocus.tnam"
            >
              <img src="../static/img/default_original.png" slot="media" class="usricon" />
            </f7-list-item>
            <f7-list-item divider title="Details"></f7-list-item>
            <f7-list-item header="Dates" :title="abrepfocus.dates"></f7-list-item>
            <f7-list-item header="Reason" :title="abrepfocus.reason_detail || abrepfocus.reason"></f7-list-item>
            <f7-list-item header="Notes">
              <div slot="subtitle" class="overflow-new-line">{{ abrepfocus.notes || '-' }}</div>
            </f7-list-item>
            <f7-list-item title="Contacts" :after="abrepfocus.exposure"></f7-list-item>
          </f7-list>
          <f7-block>
            <f7-block-title>Symptoms</f7-block-title>
            <f7-block v-if="abrepfocus.symptoms">
               <f7-chip v-for="unsyn in abrepfocus.symptoms" :key="unsyn" :text="unsyn"></f7-chip>
            </f7-block>
            <f7-block v-else>No symptoms reported</f7-block>
          </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['gdate'],
  data(){
    return {
      allclear:{},
      allabrep:{},
      allskns:{},
      abrepfocus:{},
      Allcleareadaaya:[],
      pendinghealthcahec: [],
      arrayofausente: []
      }
  },
  computed: {
    ...mapState(['mysettings','userinfo','staff']),
    humandate(){
      return this.$moment(this.gdate,'MM-DD-YYYY').format('LL')
    }
  },
  created(){

  },
  mounted(){
    this.getfreshdata();
  },
  methods:{
    getfreshdata(){
     this.$f7.dialog.preloader('Loading...');
      let ahoramom=this.$moment(this.gdate,'MM-DD-YYYY');
      let ano = ahoramom.format('YYYY');
      let mes = ahoramom.format('MM');
      let dia = ahoramom.format('DD');
     let promix=[
        this.$firebase.database().ref('daily/'+this.userinfo.uid+'/bystadaily/'+ano+'/'+mes+'/'+dia).once('value'),
        this.$firebase.database().ref('abrep/'+this.userinfo.uid+'/bystadaily/'+ano+'/'+mes+'/'+dia).once('value'),
        this.$firebase.database().ref('skns/'+this.userinfo.uid+'/staff/'+ano+'/'+mes+'/'+dia).once('value')
      ];
      return Promise.all(promix).then(snpsho=>{
        let misdaots= snpsho[0].val() || {};
        let misabs= snpsho[1].val() || {};
         let mykns= snpsho[2].val() || {};
           this.allskns=Object.assign({},mykns);
            console.log('allskns',this.allskns);
        this.allclear=Object.assign({},misdaots);
          console.log('allclear',this.allclear)
        this.allabrep=Object.assign({},misabs);
         console.log('allabrep',this.allabrep)
         this.$f7.dialog.close();
         this.listaDeCleared();
      }).catch(error=>{
        console.log(error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
      });
    },
    listaDeCleared(){
      let allstaffhere = Object.values(this.staff).filter(staff=>!staff.inkt)||{};
      let learedarray = Object.keys(this.allclear) || []
      let arrayofAbsecen = Object.keys(this.allabrep || {}) || []
      let ausentes={};
      arrayofAbsecen.forEach(unstuabsente => {
        let laabsec=this.allabrep[unstuabsente];
        laabsec.id = unstuabsente;
        laabsec.tnam = (this.staff[unstuabsente].fnm+' '+this.staff[unstuabsente].lnm)
         ausentes[unstuabsente]=laabsec
      });
       let allskns = this.allskns || {}
      let allcleared={};
      learedarray.forEach(tfid => {
          let elstu = this.staff[tfid];
          allcleared[tfid]={
            tnam: elstu.fnm+' '+elstu.lnm,
            id: tfid,
            cleared: this.allclear[tfid],
            skn: this.allskns[tfid]?this.allskns[tfid].w : null
          };
      });
      let pendinghealthcahec=allstaffhere.filter(onestuhere => {
          return !allcleared[onestuhere.tfid] && !ausentes[onestuhere.tfid];
      });
      this.pendinghealthcahec= pendinghealthcahec;
      let arrayofausentes = Object.values(ausentes) || [];
      arrayofausentes.sort((a, b) => {
                    const x = a.tnam.toLowerCase();
                    const y = b.tnam.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                      return 0
                    }
                });
                this.arrayofausente=arrayofausentes;
                console.log('arrayofausente',arrayofausentes)
      let cleareadaaya =Object.values(allcleared) || [];
        cleareadaaya.sort((a, b) => {
                    const x = a.tnam.toLowerCase();
                    const y = b.tnam.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                      return 0
                    }
                });
      this.Allcleareadaaya = cleareadaaya;
    },
  }
}
</script>
<style>
.usricon{
  width: 40px;
  border-radius: 50%;
}
.coorli .item-title{
  font-weight: bold!important;
}
.boldertitl.block-title{
      color: #366d50;
    font-weight: bold;
}
</style>