<template>
  <f7-page name="staff">
    <f7-navbar :title="(stafffocused.fnm+' '+stafffocused.lnm)" back-link="Back">
      <f7-nav-right><f7-link @click="deleteThisStaff()" color="gray" icon-material="delete"></f7-link></f7-nav-right>
    </f7-navbar>

<f7-block class="text-align-center no-margin-bottom margin-top">
  <img src="../static/img/default_original.png" class="focusikn" />
</f7-block>
<f7-list media-list class="no-margin liparts">
   <f7-list-item :title="stafffocused.leemail" :footer="!stafffocused.lkd?'Has not accessed besafe.school yet':'Account linked OK'" :after="(!stafffocused.lkd?'Pending':'Linked')">
      <f7-icon :material="!stafffocused.lkd?'pause_circle_outline':'verified_user'" slot="media"></f7-icon>
    </f7-list-item>
  <f7-list-item header="First name" :title="stafffocused.fnm" @click="editstaffprop('fnm')" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
  <f7-list-item header="Last name" :title="stafffocused.lnm" @click="editstaffprop('lnm')" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
   <f7-list-item header="Mobile Phone" :title="stafffocused.lemobile || '+1'" @click="editstaffprop('lemobile')" link="#">
    <f7-icon slot="after" material="create"></f7-icon>
  </f7-list-item>
   <f7-list-item
      footer="Allows to validate and register QR codes scanns"
        title="Pass Scanner">
        <f7-toggle :checked="stafffocused.skn" @toggle:change="guardaprop('skn',$event)" slot="after"></f7-toggle>
      </f7-list-item>
</f7-list>


  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['staffid'],
  data(){
    return {
  
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    stafffocused(){
      return this.$store.getters.oneStaff(this.staffid)
    }
  },
  created(){
    console.log(this.stafffocused)
  },
  methods:{
    deleteThisStaff(){
      this.$f7.dialog.confirm('Are you sure you want to delete this staff?','Confirm', ()=> {
       this.$f7.dialog.preloader('Deleting...');
        return  this.$firebase.database().ref('staff/'+this.userinfo.uid+'/'+this.staffid+'/inkt').set(true).then(()=>{
              let staffinfo = Object.assign({},this.stafffocused)
              staffinfo['inkt'] = true;
             this.$store.commit('updateStaff',{tfid:this.staffid,to:staffinfo});
              return this.$firebase.database().ref('localprivs/'+this.userinfo.uid+'/'+this.staffid+'/stas/').once('value')
              }).then(snap=>{
              let toUpdate={}
              toUpdate['localprivs/'+this.userinfo.uid+'/'+this.staffid+'/stas/'] = null;
              let gds= Object.keys(snap.val() || {})
              gds.forEach(oneg=>{
               toUpdate['settings/'+this.userinfo.uid+'/grades/'+oneg+'/stas/'+this.staffid]=null
               let local_grade=Object.assign({},this.mysettings.grades[oneg])
                delete local_grade.stas[this.staffid];
                 this.$store.commit('updateGrades',{gid:oneg,to:local_grade});
              })
              return this.$firebase.database().ref().update(toUpdate)
          }).then(()=>{
            this.$f7.dialog.close();
            this.$f7.views.main.router.back();
          }).catch(error=>{
            this.$f7.dialog.close();
            console.log(error)
            this.$f7.dialog.alert(error.message,error.code)
          });
        });
    },
    refrshme(){
      console.log('refreshing')
        this.$firebase.database().ref('students/'+this.userinfo.uid+'/'+this.staffid+'/prtemails').once('value').then((snpsht)=>{
          this.$f7.dialog.close();
          let prtemails= snpsht.val() || {};
            let studinfo = Object.assign({},this.stafffocused)
            studinfo.prtemails=Object.assign({},prtemails);
          this.$store.commit('updateStudent',{sid:this.staffid,to:studinfo});
      }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
      });
    },
    editstaffprop(cual){
      let frasex={
        fnm: 'Edit first name',
        lnm : 'Edit last name',
        lemobile: 'Edit mobile phone'
      };
      this.$f7.dialog.prompt(frasex[cual], (name)=> {
        if(!name){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          name = name.trim()
          this.guardaprop(cual,name);
        }
      },()=>{},this.stafffocused[cual]);
    },
    guardaprop(cual,name){
       this.$f7.dialog.preloader('Updating...');
            this.$firebase.database().ref('staff/'+this.userinfo.uid+'/'+this.staffid+'/'+cual).set(name).then(()=>{
              this.$f7.dialog.close();
              let staffinfo = Object.assign({},this.stafffocused)
              staffinfo[cual] = name;
              this.$store.commit('updateStaff',{tfid:this.staffid,to:staffinfo});
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
    }
  }
}
</script>
<style>
.focusikn{
  width: 130px;
    border-radius: 50%;
}
.liparts .item-media .icon{
  margin: 0 auto;
  font-size: 35px;
  margin-top: 6px;
}
</style>