<template>
    <f7-page name="visitors">
        <!-- Top Navbar -->
        <f7-navbar back-link="Back">
            <f7-nav-title title="My visitors"></f7-nav-title>
        </f7-navbar>

        <!-- List of visitors registered -->
        <f7-list media-list class="no-margin-top">
            <f7-list-item
                v-for="visitor in visitors"
                :key="visitor.vsid"
                :title="visitor.fnm+' '+visitor.lnm"
                :footer="visitor.leemail"
            ><img src="../static/img/default_original.png" slot="media" class="usricon" />
            </f7-list-item>
        </f7-list>

        <f7-block v-if="!Object.keys(visitors).length">There are no visitors registered yet.</f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['visitors'])
    }
}
</script>