<template>
  <f7-page name="one_grade">
    <f7-navbar :title="focusedgrade.nam" :subtitle="!mysettings.targetuser?(studentsHere.length+' students'):null" back-link="Back">
      <f7-nav-right v-if="!mysettings.targetuser">
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-onegradestu" icon-material="search"></f7-link>
    </f7-nav-right>
    <f7-searchbar
    v-if="!mysettings.targetuser"
      class="searchbar-onegradestu"
      expandable
      search-container=".studentslinst"
      search-in=".item-title"
    ></f7-searchbar>
    </f7-navbar>
<f7-block-title>Coordinator{{(focusedgrade.stas && Object.keys(focusedgrade.stas).length>1)?'s':''}}</f7-block-title>
<f7-list media-list class="no-margin">
    <f7-list-item v-if="!focusedgrade.stas || !Object.keys(focusedgrade.stas).length" header="email" @click="addNewCoordinatorEmail()" title="Coordinator not set" link="#" ><f7-icon material="create" slot="after"></f7-icon></f7-list-item>
  <f7-list-item class="coorli" v-for="(status,uncoordemail) in focusedgrade.stas" :key="uncoordemail" header="email" @click="deleteCoordinator(uncoordemail)" :title="uncoordemail.split('|').join('.')" link="#" :footer="status=='p'?'Has not accessed besafe.school yet':'Account linked OK'" ><f7-icon material="delete" slot="after"></f7-icon></f7-list-item>
  <f7-list-button v-if="focusedgrade.stas && Object.keys(focusedgrade.stas).length" link="#" @click="addNewCoordinatorEmail()" color="dark-green" title="Add more coordinators"></f7-list-button>
  <f7-block-footer>Coordinators will receive the absence reports for this grade.</f7-block-footer>
</f7-list>
<template v-if="!mysettings.targetuser">
<f7-list class="no-margin-bottom">
    <f7-list-item class="sudodiv" :checked="selectedStudents.length === studentsHere.length"  :indeterminate="selectedStudents.length>0 && (selectedStudents.length < studentsHere.length)" @change="supraselector" :checkbox="editList?true:null" title="Students"><f7-link v-if="studentsHere.length" slot="after" @click="editList=!editList" icon-material="create">{{editList?'Done':'Edit'}} </f7-link></f7-list-item>
</f7-list>
<f7-list class="searchbar-not-found">
    <f7-list-item title="No students found"></f7-list-item>
  </f7-list>
<f7-list media-list class="no-margin studentslinst searchbar-found">
  <f7-list-item 
     v-for="student in studentsHere"
      :key="student.sid"
      :checkbox="editList?true:null"
      :value="student.sid"
      :checked="selectedStudents.includes(student.sid)"
      @change="checkselectedstudents"
  :title="student.fnm+' '+student.lnm"
  :footer="'id: '+student.sid"
  :link="!editList?('/student/'+student.sid):null"
  >
    <img src="../static/img/default_original.png" slot="media" class="usricon" />
  </f7-list-item>
</f7-list>
<f7-list v-if="editList" class="no-margin-top" media-list>
  <f7-list-item divider class="ludivid"  title="Actions"></f7-list-item>
  <f7-list-item header="Transfer to:">
  <select slot="after-title"    
  :value="targetGrade"
  @input="targetGrade = $event.target.value" 
  name="cars" id="cars">
  <option value="">Select grade</option>
  <option v-for="grade in allbutMe" :key="grade.gid" :value="grade.gid">{{grade.nam}}</option>
</select>
  <f7-button outline @click="selecAction('transfer')" slot="after">Transfer</f7-button>
  </f7-list-item>

    <f7-list-item header="Delete" title="Delete students">
  <f7-button color="red" outline @click="selecAction('delete')" slot="after">Delete</f7-button>
  </f7-list-item>
</f7-list>
<template v-if="!mysettings.verifixts">
  <f7-block-title>Parents Linkage Requests</f7-block-title>
  <f7-list media-list>
    <f7-list-item
    v-for="(reqe,indfx) in accesRequests"
    :key="`ac${indfx}`"
    :title="reqe.xnm"
    :subtitle="`By: ${reqe.em.split('|').join('.')}`"
    >
    <f7-button outline popover-open=".managerews" slot="after" @click="linkthis(reqe)">Manage</f7-button>
    </f7-list-item>
    <f7-list-item v-if="!accesRequests.length">There are no account linkage requests</f7-list-item>
    <f7-block-footer v-else>You can create a new student or link the request to an existing student. This will automatically validate the daily health checks submitted by the users.</f7-block-footer>
  </f7-list>
  </template> 
<f7-block>
<br/>
</f7-block>
 <f7-fab :href="'/newstudent/'+gradeid" position="right-bottom" slot="fixed" color="red">
    <f7-icon material="person_add"></f7-icon>
  </f7-fab>
 <f7-popover class="popover-menu managerews">
    <f7-list>
      <f7-list-item v-if="studentsHere.length" link="#" @click="sheeaction('select')" popover-close title="Select Student to Link"></f7-list-item>
      <f7-list-item link="#" @click="sheeaction('create')" popover-close title="Create Student and Link"></f7-list-item>
      <f7-list-item link="#" @click="rejecthim()" popover-close title="Reject request"></f7-list-item>
    </f7-list>
  </f7-popover>

   <f7-sheet class="demo-sheet medioalto" :opened="sheetOpened" @sheet:closed="resetsheet()">
      <f7-toolbar>
        <div class="left">
           <f7-link color="red" sheet-close>Cancel</f7-link>
        </div>
        <div class="right">
          <f7-block>
          Manage linkage request
          </f7-block>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-list v-if="cualsheetaction=='select'" class="no-margin-top" no-hairlines-md>
          <f7-list-input
        label="Select Student"
        type="select"
        defaultValue=""
        :value="potmap"
        @input="potmap = $event.target.value"
        placeholder="Please choose..."
      >
        <option value="">Select</option>
        <option v-for="student in studentsHere" :key="student.sid" :value="student.sid">{{student.fnm+' '+student.lnm}}</option>
      </f7-list-input>
        </f7-list>
      <f7-list v-else-if="cualsheetaction=='create'" class="no-margin-top" no-hairlines-md>
        <f7-list-input
            label="First Name"
            type="text"
            placeholder="First name"
             :value="newchild.fnm"
            @input="newchild.fnm = $event.target.value"
            clear-button
          >
          </f7-list-input>
           <f7-list-input
            label="Last Name"
            type="text"
            placeholder="Last name"
             :value="newchild.lnm"
            @input="newchild.lnm = $event.target.value"
            clear-button
          >
          </f7-list-input>
        </f7-list>
        <f7-block v-if="cualsheetaction=='select' && potmap">
          <p>Link {{reqe.em?reqe.em.split('|').join('.'):'request'}} to {{$store.getters.oneStudent(potmap).fnm}}</p>
          <f7-button @click="linkreqtostu(potmap)" fill >Link</f7-button>
          </f7-block>
           <f7-block v-else-if="cualsheetaction=='create'" >
          <p >Link {{reqe.em?reqe.em.split('|').join('.'):'request'}} to {{newchild.fnm+' '+newchild.lnm}}</p>
          <f7-button @click="linkreqtostu(null)" fill >Create and link</f7-button>
          </f7-block>
      </f7-page-content>
    </f7-sheet>
    </template>
  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import { deletestu } from '../mixins/deletestu';

export default {
      mixins: [deletestu],
  props: ['gradeid'],
  data(){
    return {
     accesRequests:[],
     reqe:{},
     potmap:'',
     newchild:{
       fnm:'',
       lnm: ''
     },
     sheetOpened: false,
     cualsheetaction: 'select',
     selectedStudents:[],
     targetGrade:'',
     editList: false
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
      ...mapGetters(['orderedActivegrades']),
    focusedgrade(){
      return this.mysettings.grades[this.gradeid];
    },
    studentsHere(){
      return this.$store.getters.sudentsByGrade(this.gradeid)
    },
    allbutMe(){
      return this.orderedActivegrades.filter(grade=> grade.gid!==this.gradeid)
    }
  },
  created(){
    console.log(this.focusedgrade)
  },
  mounted(){
    this.readAccountLinkageRequests();
  },
  methods:{
    selecAction(cual){
      if(!this.selectedStudents.length){
        return this.$f7.dialog.alert('Please select at least one student','No students selected')
      }
      else if(cual=='transfer'){
        if(!this.targetGrade){
         return this.$f7.dialog.alert('Please select where you to transfer the students to','No target grade selected')
        }
        this.transferTo(this.selectedStudents,this.targetGrade);
      }
      else if(cual=='delete'){
         this.$f7.dialog.confirm('Are you sure you want to delete?','Confirm', ()=> {
        this.$f7.dialog.preloader('Deleting...');
          return  this.deleteStudentsArray(this.selectedStudents).then((respst)=>{
              this.$f7.dialog.close();
               this.selectedStudents = []
              this.editList = false
            }).catch(error=>{
              this.$f7.dialog.close();
              console.log(error)
              this.$f7.dialog.alert(error.message,error.code)
            });
          });
      }
    },
     transferTo(selectedStudents,targetGrade){
       console.log('transfering', selectedStudents,targetGrade)
       let updates={};
       let studnts={};
       selectedStudents.forEach(sid => {
          updates['students/'+this.userinfo.uid+'/'+sid+'/gid'] = targetGrade;
          studnts[sid]=this.$store.getters.oneStudent(sid);
          studnts[sid].gid=targetGrade
       });
       this.$f7.dialog.preloader('Updating...');
        return this.$firebase.database().ref().update(updates).then(()=>{
            this.selectedStudents = []
            this.targetGrade = ''
            this.editList = false
            this.$store.commit('mergeObjTo',{what:'students',to:studnts});
          this.$f7.dialog.close();
        }).catch(error=>{
           this.$f7.dialog.close();
           console.log(error)
           this.$f7.dialog.alert(error.message,error.code)
        });
     },
     supraselector (e) {
      var self = this;
      var movies = self.movies;
      if (this.selectedStudents.length < this.studentsHere.length) {
        this.selectedStudents = this.studentsHere.map(unstu=>unstu.sid);
      } else if (this.selectedStudents.length == this.studentsHere.length) {
        this.selectedStudents = [];
      }
    },
    checkselectedstudents(event) {
      const value = event.target.value;
      if (event.target.checked && !this.selectedStudents.includes(value)) {
        this.selectedStudents.push(value);
      } else {
        this.selectedStudents.splice(this.selectedStudents.indexOf(value), 1);
      }
      console.log( this.selectedStudents)
      },
    rejecthim(){
      this.$f7.dialog.confirm('By rejecting the request, you will delete the requestor data associated with this request.','Confirm', ()=> {
       this.$f7.dialog.preloader('Rejecting...');
       let updates={};
         updates['pending_stus/'+this.userinfo.uid+'/'+this.reqe.tsid] = null;
        updates['self_def/'+this.userinfo.uid+'/'+this.reqe.em+'/stus/'+this.reqe.tsid] = null;
        return this.$firebase.database().ref().update(updates).then(()=>{
              this.$f7.dialog.close();
              //actualizar requests del grade
              let neorque = this.accesRequests.filter(x => x.tsid !== this.reqe.tsid);
              this.accesRequests=neorque;
              this.sheetOpened=false;
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      });
    },
    linkreqtostu(potmapstuid){
      let studentid;
       this.$f7.dialog.preloader('Linking...');
       let cleanemail=this.reqe.em;
      let updates = {};
      let newstudent={};
      if(!potmapstuid){
        //ademas, crear al alumno
        if(!this.newchild.fnm || !this.newchild.lnm){
          return this.$f7.dialog.alert('Student names are required','Missing data');
        }
        studentid = this.reqe.tsid
        let parnt={};
         parnt[cleanemail]='linked';
          newstudent = {
            fnm: this.newchild.fnm.trim(),
            lnm: this.newchild.lnm.trim(),
            sid: studentid,
            gid: this.gradeid,
            prtemails: parnt,
            prtphone: null
          };
           updates['students/'+this.userinfo.uid+'/'+studentid] = newstudent;
      }
      else{
        studentid = potmapstuid;
        updates['students/'+this.userinfo.uid+'/'+studentid+'/prtemails/'+cleanemail] = 'linked';
      }

      //studentid es AL QUE HAY QUE VINCULARLO
      updates['localprivs/'+this.userinfo.uid+'/'+cleanemail+'/stus/'+studentid] = true;

      //reqe.tsid es el que hay que borrar
      updates['pending_stus/'+this.userinfo.uid+'/'+this.reqe.tsid] = null;
      updates['self_def/'+this.userinfo.uid+'/'+cleanemail+'/stus/'+this.reqe.tsid] = null;

      //yborrarlo de los potenciales
      return this.$firebase.database().ref().update(updates).then(()=>{
          this.$f7.dialog.close();
          if(!potmapstuid){
            //crear al student
             this.$store.commit('updateStudent',{sid:studentid,to:newstudent});
          }
          else{
             //actualizar student info
            let studinfo = Object.assign({},this.$store.getters.oneStudent(studentid));
            if(!studinfo.prtemails){
              studinfo.prtemails={};
            }
            studinfo.prtemails[cleanemail] = 'linked';
            this.$store.commit('updateStudent',{sid:studentid,to:studinfo});
          }
          //actualizar requests del grade
          let neorque = this.accesRequests.filter(x => x.tsid !== this.reqe.tsid);
          this.accesRequests=neorque;
          this.sheetOpened=false;
      }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
      });
    },
    resetsheet(){
      this.sheetOpened = false;
      this.potmap ='';
      this.newchild.fnm='';
      this.newchild.lnm='';
      this.cualsheetaction='select';
    },
    sheeaction(cual){
      this.cualsheetaction=cual;
      //tratar de parsear el nombre si es crear
      if(cual=='create'){
        let arrayprenam = this.reqe.xnm;
        let arryado = arrayprenam.split(' ');
        if(arryado.length==2){
          this.newchild.fnm=arryado[0];
          this.newchild.lnm=arryado[1];
        }
        else if(arryado.length==3){
          this.newchild.fnm=arryado[0]+' '+arryado[1];
          this.newchild.lnm=arryado[2];
        }
        else{
          this.newchild.fnm=this.reqe.xnm;
        } 
      }
      this.sheetOpened = true;
    },
    linkthis(reqe){
      this.reqe = Object.assign({},reqe);
    },
    readAccountLinkageRequests(){
      this.$f7.dialog.preloader('Loading...');
      this.accesRequests=[];
      this.$firebase.database().ref('pending_stus/'+this.userinfo.uid).orderByChild('gid').equalTo(this.gradeid).once('value').then(vres=>{
         this.$f7.dialog.close();
         let reqeust=vres.val() || {};
         let arrayad=Object.values(reqeust) || [];
         this.accesRequests=arrayad;
         console.log(arrayad);
      }).catch(error=>{
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message,error.code);
      });
    },
    deleteCoordinator(emailx){
      this.$f7.dialog.confirm('Are you sure you want to revoke this absences report recipient and linked access email ('+(emailx.split('|').join('.'))+') ?','Confirm', ()=> {
        console.log('eliminar acceso',emailx)//todo
          this.$f7.dialog.preloader('Revoking...');
          //aqui y en localprivs
          let updates={};
           updates['localprivs/'+this.userinfo.uid+'/'+emailx+'/stas/'+this.gradeid] = null;
          updates['settings/'+this.userinfo.uid+'/grades/'+this.gradeid+'/stas/'+emailx] = null;
            this.$firebase.database().ref().update(updates).then(()=>{
              this.$f7.dialog.close();
               let pregradeinfo=Object.assign({},this.mysettings.grades[this.gradeid]);
              delete pregradeinfo.stas[emailx];
              this.$store.commit('updateGrades',{gid:this.gradeid,to:pregradeinfo});
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      });
    },
    addNewCoordinatorEmail(){
      this.$f7.dialog.prompt('Set new coordinator email', (name)=> {
        if(!name){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          name = name.trim()
          let cleanemail=name.split('.').join('|');
          this.$f7.dialog.preloader('Saving...');
          //confimar si existe
          return this.$firebase.database().ref('staff/'+this.userinfo.uid+'/'+cleanemail).once('value').then(snpst=>{
            if(!snpst.exists()){
              throw new Error('notfound');
            }
            let preexisting=snpst.val();
              let updates = {};
              updates['localprivs/'+this.userinfo.uid+'/'+cleanemail+'/stas/'+this.gradeid] = true;
              updates['settings/'+this.userinfo.uid+'/grades/'+this.gradeid+'/stas/'+cleanemail] = preexisting.lkd ? 'linked':'p';
              return this.$firebase.database().ref().update(updates)
          }).then(()=>{
              this.$f7.dialog.close();
              let pregradeinfo=Object.assign({},this.mysettings.grades[this.gradeid]);
              if(!pregradeinfo.stas){
                pregradeinfo.stas={};
              }
              pregradeinfo.stas[cleanemail]='p';
              this.$store.commit('updateGrades',{gid:this.gradeid,to:pregradeinfo});
          }).catch(error=>{
              this.$f7.dialog.close();
              if(error.message=='notfound'){
                 return this.$f7.dialog.alert('Staff not found, please specify name and last name','Not found',()=>{
                  this.$f7.views.main.router.navigate('/newstaff/'+cleanemail+'/'+this.gradeid);
                });
              }
              else{
                 this.$f7.dialog.alert(error.message,'Error');
              }
          });
        }
      });
    },
    chgStudentName(studentid,toupdate){
      console.log('cambiando',studentid,toupdate)
      this.$f7.dialog.preloader('Saving...');
         this.$firebase.database().ref('students/'+this.userinfo.uid+'/'+studentid).update(toupdate).then(()=>{
          this.$f7.dialog.close();
          this.$store.commit('updateStudent',{sid:studentid,to:toupdate});
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
    }
  }
}
</script>
<style>
.usricon{
  width: 40px;
  border-radius: 50%;
}
.coorli .item-title{
  font-weight: bold!important;
}
.actoninvite{
  width: 300px;
}
.medioalto{
  height: 50vH;
}
.sudodiv{
  background: #e4fff8;
}
.sudodiv .item-title{
  font-weight: bold;
}
.ludivid{
  height: 26px!important;
  line-height: 26px!important;
}

</style>