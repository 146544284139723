<template>
    <f7-page name="visitorsdailydata">
        <f7-navbar title="All visitors" :subtitle="humandate" back-link="Back"></f7-navbar>

        <f7-block-title class="boldertitl">Visitors with a cleared health check</f7-block-title>
        <f7-list media-list class="no-margin">
            <f7-list-item
                v-for="visitor in visitorsToday"
                :key="visitor.vsid"
                :footer="visitor.daily.wh?('Cleared at: '+$moment.unix(visitor.daily.wh).format('HH:mm')):'Not cleared'"
                :after="visitor.scans ? `Scanned at: ${$moment.unix(visitor.scans.w).format('HH:mm')}` : 'Pending scan'"
                :title="`${visitor.fnm} ${visitor.lnm}`"
                :subtitle="visitor.leemail"
                :text="visitor.daily.reason !== '' ? `Reason: ${visitor.daily.reason}` : 'No reason'">
                <img src="../static/img/default_original.png" slot="media" class="usricon" />
            </f7-list-item>
        </f7-list>
        <f7-block v-if="!Object.keys(visitorsToday).length">No cleared visitors on {{humandate}}</f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["gdate"],

    computed: {
        ...mapState(["visitorsToday"]),

        humandate () {
            return this.$moment(this.gdate,'MM-DD-YYYY').format('LL')
        },
    },
}
</script>