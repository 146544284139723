<template>
  <f7-page name="reports" @page:beforein="loadAllSettings({andstudents:true,andstaff:true,nopreloader:true})">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Reports" subtitle="Verified students and staff"></f7-nav-title>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".virtual-list"
          search-item="li"
          search-in=".item-title"
          :disable-button="true"
        ></f7-searchbar>
      </f7-subnavbar>
    </f7-navbar>
    <!-- Page content-->
    <f7-list class="searchbar-not-found no-margin-top">
      <f7-list-item title="No results"></f7-list-item>
    </f7-list>
    <f7-list
      ref="stulist"
      class="searchbar-found no-margin-top"
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: 52}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          media-item
          :link="item.s?('/reportdetail/'+item.sid):('/reportdetail_staff/'+item.sid)"
          :title="item.title"
          :after="item.after"
          :style="`top: ${vlData.topPosition}px`"
        ></f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';

export default {
    mixins: [loadsettings],
  data (){
      return {
        vlData: {
          items: [],
        },
      };
  },
   computed: {
   ...mapState(['userinfo','mysettings']),
    items(){
      let allstudents = Object.values(this.$store.state.students || {}) || [];
      let soloactivos = allstudents.filter(student => {
            return !student.inkt
        });
      let mapedxstu = soloactivos.map(onestu=>{
        return {
          sid: onestu.sid,
          title: `${onestu.lnm}, ${onestu.fnm}`,
          after: this.mysettings.grades[onestu.gid].nam,
          s: true
        }
      });
      let allstaff = Object.values(this.$store.state.staff || {}) || [];
      let mapedxsta = allstaff.map(onestaff=>{
        return {
          sid: onestaff.tfid,
          title: `${onestaff.lnm}, ${onestaff.fnm}`,
          after: 'Staff'
        }
      });
      let alltogheter = mapedxstu.concat(mapedxsta);
        alltogheter.sort((a, b) => {
              const x = a.title.toLowerCase();
              const y = b.title.toLowerCase();
              if(x > y){
                  return 1
              }
              else if(x < y){
                  return -1
              }
              else{
                return 0
              }
          });
      return alltogheter;
    }
 
  },
  watch:{
    items(neostudents){
      console.log('hay nuevos students',neostudents);
      this.$refs.stulist.f7VirtualList.replaceAllItems(neostudents);
    }
  },
  mounted(){
    
  },
  created(){
    
  },
  methods:{
    searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      }
  }
}
</script>
<style>

</style>