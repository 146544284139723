<template>
  <f7-page name="one_account">
    <f7-navbar :title="focusedAccount.schoolname" back-link="Back">
      <f7-nav-right>
        <f7-link icon-material="home" @click="getdomainsatus()"></f7-link>
      </f7-nav-right>
    </f7-navbar>

   <f7-list class="profile no-margin-top no-margin-bottom" >
        <f7-list-item divider title="Account Info"></f7-list-item>
        <f7-list-item header="Institution name" :title="focusedAccount.schoolname" >
          <f7-icon class="text-color-gray" slot="media" material="account_balance"></f7-icon>
        </f7-list-item>
          <f7-list-item external target="_blank"  :link="'https://'+focusedAccount.domain+'.besafe.school'" :header="`Subdomain (${focusedAccount.domain || 'schoolname'}.besafe.school)`" :title="focusedAccount.domain">
          <f7-icon class="text-color-gray" slot="media" material="public"></f7-icon>
           <f7-icon class="text-color-gray" slot="after" material="launch"></f7-icon>
        </f7-list-item>
         <f7-list-item title="Status" :footer="accountid" :after="focusedAccount.status || 'pending'" >
        </f7-list-item>
         </f7-list>
          <f7-list v-if="focusedAccount.ft" class="profile no-margin-top" >
          <f7-list-item divider title="Free Trial"></f7-list-item>
          <f7-list-item title="Requested" :after="$moment(focusedAccount.ft.rq).format('LL')"></f7-list-item>
          <f7-list-item title="Valid Thru" :after="focusedAccount.ft.vt?$moment(focusedAccount.ft.vt).format('LL'):null">
            <f7-button slot="after" @click="startPlan(true)" outline v-if="!focusedAccount.domaindata">Activate Trial</f7-button>
            <span v-else>{{focusedAccount.domaindata.vt}}</span>
          </f7-list-item>
      </f7-list>

    <f7-list v-for="(suscr,suscrid) in focusedAccount.subs" media-list :key="suscrid" class="no-margin">
      <f7-list-item divider title="Paypal Subscription"></f7-list-item>
      <f7-list-item title="Status" :footer="suscr.meta.subscriber?suscr.meta.subscriber.email_address:null" :badge-color="suscr.meta.status=='ACTIVE'?'blue':'red'" :badge="suscr.meta.status"></f7-list-item>
      <f7-list-item v-if="suscr.meta.status=='ACTIVE'"  title="Plan" :after="mapxs[suscr.meta.plan_id].type"></f7-list-item>
      <f7-list-item v-if="false && suscr.meta.status=='ACTIVE'" title="Next billing" :after="suscr.meta.billing_info.next_billing_time.split('T')[0]">
        <f7-chip slot="subtitle" v-if="$moment(suscr.meta.billing_info.next_billing_time).isBefore($moment())" text="Overdue" color="red"></f7-chip>
        <f7-chip slot="subtitle" v-else :title="$moment(suscr.meta.billing_info.next_billing_time).fromNow()"></f7-chip>
      </f7-list-item>
    </f7-list>
   <f7-block><f7-button @click="startPlan()" v-if="focusedAccount.status!='Active' && focusedAccount.status!='Trial'" outline >Activate Plan</f7-button></f7-block>


       <f7-card >
            <f7-card-header class="cardheaderflex"> <f7-list class="no-margin">
      <f7-list-item divider title="School banner"></f7-list-item></f7-list><input type="file" id="myimg-input" @change="filesChange($event)" accept="image/png" /></f7-card-header>
            <f7-card-content class="maxaltofoto" :padding="false">
              <img class="limitlogo" :src="focusedAccount.logo || '../static/img/default_logo.png'"/>
            </f7-card-content>
          </f7-card>
      <f7-block v-if="myinfo.fd">
        <f7-button @click="impersonate()">Impersonate</f7-button>
      </f7-block>
  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['accountid'],
  computed: {
    ...mapState(['allAccounts','mapxs','myinfo']),
    focusedAccount(){
      return this.allAccounts[this.accountid]
    }
  },
  created(){
    console.log(this.focusedAccount)
  },
  methods:{
  impersonate(){
        this.$f7.dialog.preloader('Getting token...');
      let impersonate = this.$firebase.functions().httpsCallable('impersonate');
     return impersonate({disfraz: this.accountid }).then((ladata)=> {
        let payload=ladata.data;
        console.log(payload)
        if(payload.error){
          throw new Error(payload.error);
        }
       return this.$firebase.auth().signInWithCustomToken(payload.sent);
      }).then(()=>{
        location.reload();
      }).catch((error)=>{
        this.$f7.dialog.close();
        console.log('error en fx',error);
        this.$f7.dialog.alert(error.message,'Error');
      });
  },
    startPlan(trial){
      console.log('setting up domain accoun via plan suscription');
       this.$f7.dialog.preloader('Requesting...');
       let dothis = {};
       dothis['domains/'+this.focusedAccount.domain+'/u/'] = this.accountid;
       if(trial){
          dothis['domains/'+this.focusedAccount.domain+'/vt'] = this.$moment().add(14,'d').format('YYYY-MM-DD');
          dothis['myinfo/'+this.accountid+'/status'] = 'Trial';
       }
       else{
         dothis['myinfo/'+this.accountid+'/status'] = 'Active';
         dothis['domains/'+this.focusedAccount.domain+'/vt'] = null;
       }
       this.$firebase.database().ref().update(dothis).then(()=>{
          this.$f7.dialog.close();
              let toastCenter = this.$f7.toast.show({
                text: 'Account activated',
                position: 'bottom',
                closeTimeout: 2500,
                destroyOnClose: true
            });
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
    }
  }
}
</script>