<template>
  <f7-page name="newstudent">
    <f7-navbar title="Create new student" :subtitle="focusedgrade.nam" back-link="Back"></f7-navbar>
    <f7-block-title>Add new student</f7-block-title>

 <f7-list class="no-margin" no-hairlines-md>
      <f7-list-input
        label="First name *"
        type="text"
        placeholder="First name"
         :value="neo_firstname"
        @input="neo_firstname = $event.target.value"
        clear-button
        required
    validate
      >
      </f7-list-input>

       <f7-list-input
        label="Last name *"
        type="text"
        placeholder="Last name"
         :value="neo_lastname"
          @input="neo_lastname = $event.target.value"
        clear-button
        required
    validate
      >
      </f7-list-input>
      </f7-list>

    <f7-block-title >In order to receive <b>validated</b> daily health checks or absence reports, a parent account must be active.</f7-block-title>

  <f7-list class="no-margin" no-hairlines-md>
<f7-list-item divider title="Parents account"></f7-list-item>

        <f7-list-input
        label="Parent email"
        type="email"
        placeholder="parent@email.com"
         :value="parentEmail"
        @input="parentEmail = $event.target.value"
        clear-button
      >
      </f7-list-input>
       <f7-list-input
        label="Phone"
        type="tel"
         :value="parent_mobile"
        @input="parent_mobile = $event.target.value"
        placeholder="Parent's mobile phone"
        clear-button
      >
      <span slot="info">If present, you will be able to text them relevant information</span>
      </f7-list-input>
          <f7-block-footer >If parents log in with the specified email, their account will be automatically linked.<br/>Otherwise, you will be able to send them a custom reedemable code for account linkage.</f7-block-footer>

      </f7-list>

<f7-block>
    <f7-button fill @click="createNewStudent()" color="teal" large icon-material="person_add">Create</f7-button>
</f7-block>




  </f7-page>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: ['gradeid'],
  data(){
    return {
      neo_firstname:'',
      neo_lastname:'',
      parent_mobile: '+1',
      parentEmail: ''
    }
  },
  computed: {
    ...mapState(['mysettings','userinfo']),
    focusedgrade(){
      return this.mysettings.grades[this.gradeid];
    },
    studentsHere(){
      return this.$store.getters.sudentsByGrade(this.gradeid)
    }
  },
  created(){
    console.log(this.focusedgrade)
  },
  methods:{
    createNewStudent(){
      if(!this.neo_firstname ||!this.neo_lastname){
        return this.$f7.dialog.alert('Student names are required','Missing data');
      }
      else{
         let parnt={};
         let cleanemail=null;
        if(this.parentEmail){
          cleanemail=this.parentEmail.split('.').join('|').toLowerCase();
           parnt[cleanemail]='p';
        }
         let studentid = this.$firebase.database().ref('students/'+this.userinfo.uid).push().key;
          let toupdate = {
            fnm: this.neo_firstname.trim(),
            lnm: this.neo_lastname.trim(),
            sid: studentid,
            gid: this.gradeid,
            prtemails: parnt,
            prtphone: (this.parent_mobile && this.parent_mobile!='+1')?this.parent_mobile : null
          };
          this.$f7.dialog.preloader('Creating...');
          let updates = {};
          updates['students/'+this.userinfo.uid+'/'+studentid] = toupdate;
          if(cleanemail){
            updates['localprivs/'+this.userinfo.uid+'/'+cleanemail+'/stus/'+studentid] = true;
          }
          return this.$firebase.database().ref().update(updates).then(()=>{
            if(!cleanemail){
              let gencodeforem = this.$firebase.functions().httpsCallable('gencodeforem');
              return gencodeforem({studentid: studentid });
            }
            else{
              return false;
            }
            }).then(ladata=>{
              if(!cleanemail && ladata){
                let payload=ladata.data;
                console.log(payload);
                if(payload.error){
                  throw new Error(payload.error);
                }
               }
              this.$f7.dialog.close();
              this.$store.commit('updateStudent',{sid:studentid,to:toupdate});
              this.$f7.views.main.router.back();
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
      }
    }
  }
}
</script>
<style>

</style>