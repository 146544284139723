<template>
  <f7-page name="dashboard" @page:beforein="loadAllSettings({andstudents:true, andstaff: true})">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Dashboard" :subtitle="$moment(fechaselect,'MM/DD/YYYY').format('LL')"></f7-nav-title>
    </f7-navbar>
    <!-- Page content-->
<f7-toolbar class="forceblock" position="top">
      <f7-list class="no-margin" no-hairlines-md>
      <f7-list-input
        type="datepicker"
        @change="cambiandofecha($event.target.value)"
        :calendar-params="{dateFormat: 'mm/dd/yyyy', value:[today], closeOnSelect: true, disabled: {
        from: today
        }}"
        readonly
      >
        <f7-icon class="fixcalicon" material="today" slot="media"></f7-icon>
      </f7-list-input>
      </f7-list>
    </f7-toolbar>
<f7-block-title>Students</f7-block-title>
 <f7-row>
    <f7-col
     width="100" medium="33"
       v-for="grade in orderedActivegrades"
      :key="grade.gid"
     >
      <onegrade :grade="grade" :fechafocus="fechaselect"></onegrade>
     </f7-col>
 </f7-row>

<f7-block-title>Staff</f7-block-title>
 <f7-row>
    <f7-col
     width="100" medium="33"
     >
      <staffdash :fechafocus="fechaselect"></staffdash>
     </f7-col>
 </f7-row>

<f7-block-title>Visitors</f7-block-title>
 <f7-row>
    <f7-col
     width="100" medium="33"
     >
      <visitorsdash :fechafocus="fechaselect"></visitorsdash>
     </f7-col>
 </f7-row>




  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';
import onegrade from '../components/onegrade.vue'
import staffdash from '../components/staffdash.vue'
import visitorsdash from '../components/visitorsdash.vue';

export default {
    mixins: [loadsettings],
    components:{
      onegrade,
      staffdash,
      visitorsdash
    },
  data (){
    return {
      fechaselect: this.$moment().format('MM/DD/YYYY'),
      today: new Date,
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings']),
    ...mapGetters(['orderedActivegrades'])
  },
  mounted(){

  },
  created(){

  },
  methods:{
     cambiandofecha(valorx){
      console.log('nueva fecha',valorx)
      this.fechaselect = valorx
    },
  }
}
</script>
<style>
.liboldtitle .item-title{
  font-weight: bold!important;
}
.sudobage{
  left: 23px!important;
    top: -9px!important;
    font-weight: bold;
    height: 18px;
    width: 20px;
    padding: 0px 13px;
    font-size: 12px;
}
.fixic .icon{
  margin: 0 auto;
}
.forceblock .toolbar-inner{
  display: block!important;
}
</style>