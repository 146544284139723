<template>
    <f7-button fill @click="impersonate">Impersonate</f7-button>
</template>

<script>
export default {
    methods: {
        impersonate(){
            this.$f7.dialog.preloader('Impersonating as admin...');

            const impersonateAdmin = this.$firebase.functions().httpsCallable('impersonateAdmin');

            return impersonateAdmin().then(response => {

                let payload = response.data;

                if(payload.error) throw new Error(payload.error);

                return this.$firebase.auth().signInWithCustomToken(payload.token);
                
            }).then(() => {
                location.reload();
            }).catch(error => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, 'Error');
            });
        }
    }
}
</script>