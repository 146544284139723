<template>
  <f7-page name="institution" @page:beforein="loadAllSettings({})">
    <!-- Top Navbar -->
    <f7-navbar title="Institution" subtitle="Structure" back-link="Back">
      
    </f7-navbar>
    <!-- Page content-->
    <f7-block-title>Define your institution structure</f7-block-title>
     <f7-block-footer>
      <p>You can add more grades/levels (7th A, 7th B) using the plus sign at the bottom right of the screen.<br/>You can disable grades/levels with the toggle switch<br/>You can reorder grade/levels by dragging and dropping from the left of the list item.</p>
    </f7-block-footer>
      <f7-list class="mygrades" sortable sortable-opposite @sortable:sort="onSort">
      <f7-list-item
      v-for="grade in orderedgrades"
      :key="grade.gid"
      :footer="grade.inactive?'Inactive':'Active'"
        :title="grade.nam">
        <f7-toggle :checked="!grade.inactive" @toggle:change="cambiand(grade.gid,$event)" slot="after"></f7-toggle>
      </f7-list-item>
    </f7-list>

    <f7-fab @click="addNewGrade(null)" position="right-bottom" slot="fixed" color="red">
    <f7-icon material="add"></f7-icon>
  </f7-fab>
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loadsettings } from '../mixins/loadsettings';

export default {
     mixins: [loadsettings],
  data (){
    return {
      grades:{}
    }
  },
   computed: {
   ...mapState(['userinfo','mysettings']),
    // ...mapGetters(['']),
    orderedgrades(){
      let mygrades= this.mysettings.grades || {};
      let aryadso=Object.values(mygrades) || [];
      aryadso.sort((a, b)=> {
        return a.ord - b.ord;
      });
      return aryadso;
    }
  },
  mounted(){
    this.$f7.sortable.enable('.mygrades');
  },
  created(){
    
  },
  methods:{
    cambiand(gid,valor){
      console.log('cambiando',gid,valor)
      let inactive=valor?null:true;
      this.$f7.dialog.preloader('Saving...');
         this.$firebase.database().ref('settings/'+this.userinfo.uid+'/grades/'+gid+'/inactive').set(inactive).then(()=>{
          this.$f7.dialog.close();
          let pregradeinfo=this.mysettings.grades[gid];
          pregradeinfo.inactive=inactive;
          this.$store.commit('updateGrades',{gid:gid,to:pregradeinfo});
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
    },
    addNewGrade(existingrade){
      console.log('adding grade')
      this.$f7.dialog.prompt('What is the grade/level name?', (name)=> {
        if(!name){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          if(!existingrade){
             this.createGrade(name);
          }
        }
      });
    },
    createGrade(name){
        let gid = this.$firebase.database().ref('settings/'+this.userinfo.uid+'/grades').push().key;
        let prevgrades = this.mysettings.grades || {};
        let cuants = Object.values(prevgrades) || [];
        let tosave = {
            gid: gid,
            ord: cuants.length,
            nam: name.trim()
          }
        this.$f7.dialog.preloader('Saving...');
         this.$firebase.database().ref('settings/'+this.userinfo.uid+'/grades/'+gid).set(tosave).then(()=>{
          this.$f7.dialog.close();
          this.$store.commit('updateGrades',{gid:gid,to:tosave});
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
      
    },
    onSort(data) {
        // Sort data
         this.$f7.dialog.preloader('Saving new order...');
        console.log(data);
         let prevgrades = JSON.parse(JSON.stringify(this.mysettings.grades));
        let clonado = this.orderedgrades.slice(0);
        clonado.splice(data.to, 0, clonado.splice(data.from, 1)[0]);
        console.log('listo es',clonado );
        clonado.forEach((element,idx) => {
            prevgrades[element.gid].ord=idx;
            delete prevgrades[element.gid].count;
        });
        console.log(prevgrades);
        this.$firebase.database().ref('settings/'+this.userinfo.uid+'/grades').set(prevgrades).then(()=>{
           this.$f7.dialog.close();
          this.$store.commit('updateAllGrades',prevgrades);
        }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
      }
  }
}
</script>
<style>
.mygrades{
  margin-bottom: 80px!important;
}
</style>