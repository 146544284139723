<template>
  <f7-page name="settings" @page:beforein="loadAllSettings({})">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>Settings</f7-nav-title>
    </f7-navbar>
    <!-- Page content-->
    <f7-block-title>Authentication</f7-block-title>
     <f7-block class="no-margin-bottom">Specify if you want to allow anonymous users. This limits the app only to generate QR 'passports', to scan them, and receive emails for absences reports (all from unverified senders).</f7-block>
    <f7-list class="margin-top-half unsetwhite">
    <f7-list-item footer="Allows creation of structured grades/levels, and analytics features for verified senders." radio value="authenticated" name="targetusers" :checked="!mysettings.targetuser" @change="setCoreSetting('targetuser',$event.target.value)" title="Login with Google or username/password"></f7-list-item>
    <f7-list-item footer="Anybody can submit unverified absence forms" class="disabled" radio value="anonymous" name="targetusers" :checked="mysettings.targetuser=='anonymous'" @change="setCoreSetting('targetuser',$event.target.value)" title="Anonymous users"></f7-list-item>
    <f7-block-footer>'Anonymous mode' is no longer available. Still interested? We can re-enable it for your account after discussing the implications.<br/>Contact us at info@besafe.school</f7-block-footer>
  </f7-list>
  <template v-if="!mysettings.targetuser">
    <f7-block-title>Accounts verifications</f7-block-title>
    <f7-block class="no-margin-bottom">You can restrict parents' account verification only to accounts created by your institution, either by specifying parents emails and/or sharing a code with each parent. Or, you can allow authenticated users to create accounts and validate the requests manualy.</f7-block>
    <f7-list class="margin-top-half unsetwhite">
    <f7-list-item footer="By specifying parents emails or sharing codes with each parent" radio value="onlyverified" name="verifixts" :checked="mysettings.verifixts === 'onlyverified'" @change="setCoreSetting('verifixts',$event.target.value)" title="Only accounts created by your institution"></f7-list-item>
    <f7-list-item footer="Allow end-user verification requests" radio value="allowreqs" name="verifixts" :checked="!mysettings.verifixts" @change="setCoreSetting('verifixts',$event.target.value)" title="Accounts created by your institution AND allow users to create accounts, and manualy verify their requests"></f7-list-item>
    <f7-block-footer></f7-block-footer>
  </f7-list>

    <f7-block class="no-margin-bottom">Specify if you want to allow faculty/staff to answer daily health checks and absence reports.</f7-block>
    <f7-list class="margin-top-half">
        <f7-list-item checkbox :checked="!mysettings.nofaculty" @change="setCoreSetting('nofaculty',$event.target.checked)" title="Include Faculty/Staff"></f7-list-item>
    </f7-list>

    <f7-block class="no-margin-bottom">Specify if you want to allow visitors to answer daily checks.</f7-block>
    <f7-list class="margin-top-half">
        <f7-list-item checkbox :checked="mysettings.visitors" @change="setCoreSetting('visitors', $event.target.checked)" title="Allow visitors"></f7-list-item>
    </f7-list>
  </template>

    <f7-list class="margin-top-half">
      <f7-list-item divider title="Default Email Recipient for Absence Reports"></f7-list-item>
        <f7-list-item
         :title="myinfo.email || userinfo.email"
         link="#"
         @click="setRecipientEmail()"
      >
      <f7-icon slot="after" material="create"></f7-icon>
      </f7-list-item >
      <f7-block-footer>You can override this default recipient and define custom recipients for each grade. Go to the 'My Institution' module, and create coordinator(s) for each grade.</f7-block-footer>
    </f7-list>



  </f7-page>
</template>

<script>
import { loadsettings } from '../mixins/loadsettings';

import { mapState, mapGetters } from 'vuex';

export default {
   mixins: [loadsettings],
  data (){
    return {

    }
  },
   computed: {
   ...mapState(['userinfo','myinfo','mysettings']),
    // ...mapGetters(['']),

  },
  mounted(){
    console.log("mysettings", this.mysettings);
  },
  created(){

  },
  methods:{
     setRecipientEmail(){
      this.$f7.dialog.prompt('Set new recipient email', (email)=> {
        if(!email){
          return this.$f7.dialog.alert('Field cannot be empty','Error');
        }
        else{
          email = email.trim()
          this.$f7.dialog.preloader('Saving...');
          return this.$firebase.database().ref('myinfo/'+this.userinfo.uid+'/email').set(email).then(()=>{
              this.$f7.dialog.close();
            this.$store.commit('profile_setNeoEmail', email);
          }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
          });
        }
      });
    },
    setCoreSetting(target,value){
      if(target=='targetuser'){
        value = value=='authenticated'?null:value;
      }
      else if(target=='verifixts'){
        value = value=='allowreqs'?null:value;
      }
      else if(target=='nofaculty'){
        value = !value;
      }

      this.$f7.dialog.preloader('Saving...');
      this.$firebase.database().ref('settings/'+this.userinfo.uid+'/'+target).set(value).then(()=>{
          this.$f7.dialog.close();
          console.log('coresetting saved')
          this.$store.commit('updateCoreSetting',{what:target,to:value});
       }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
       });
    }
  }
}
</script>
<style>
.unsetwhite .item-title{
  white-space: unset;
}
</style>