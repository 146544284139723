<template>
  <f7-page name="questions" @page:beforein="loadAllSettings({})">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Questions" subtitle="Customization"></f7-nav-title>
    </f7-navbar>
    <!-- Page content-->
    <f7-block-title>Symptoms to assess in students</f7-block-title>
    <f7-list
      class="mystuquestions"
      sortable
      sortable-opposite
      @sortable:sort="onSort($event, 'stu')"
    >
      <f7-list-item
        v-for="simtom in stu"
        :key="simtom.i"
        :title="simtom.n"
        :footer="simtom.hide ? 'Inactive' : 'Active'"
      >
        <f7-link
          slot="title"
          @click="editarNane('stu', simtom.i)"
          icon-material="create"
        ></f7-link>
        <f7-toggle
          :checked="!simtom.hide"
          @toggle:change="cambiand(simtom.i, 'stu', $event)"
          slot="after"
        ></f7-toggle>
      </f7-list-item>
    </f7-list>

    <template v-if="!mysettings.nofaculty">
      <f7-block-title>Symptoms to assess in staff/faculty</f7-block-title>
      <f7-list
        class="mystaquestions"
        sortable
        sortable-opposite
        @sortable:sort="onSort($event, 'sta')"
      >
        <f7-list-item
          v-for="simtom in sta"
          :key="simtom.i"
          :title="simtom.n"
          :footer="simtom.hide ? 'Inactive' : 'Active'"
        >
          <f7-link
            slot="title"
            @click="editarNane('sta', simtom.i)"
            icon-material="create"
          ></f7-link>
          <f7-toggle
            :checked="!simtom.hide"
            @toggle:change="cambiand(simtom.i, 'sta', $event)"
            slot="after"
          ></f7-toggle>
        </f7-list-item>
      </f7-list>
    </template>

    <f7-block-title>Contacts question</f7-block-title>
    <f7-list>
      <f7-list-item>
        <div slot="title" class="contacts-question">
          {{ contactsQuestion }}
        </div>
        <f7-button 
          v-if="contactsQuestion !== defaultQuestion"
          @click="setConctactsQuestionDefault" 
          class="margin-right" 
          slot="after" 
          color="primary">
          Default
        </f7-button>
        <f7-link
          @click="editContactsQuestion"
          slot="after"
          icon-material="create"
        ></f7-link>
      </f7-list-item>
    </f7-list>

    <f7-block
      >You can reorder, edit, inactivate and create new symptoms for your
      institution.</f7-block>
    <f7-fab position="right-bottom" slot="fixed" color="red">
      <f7-icon material="add"></f7-icon>
      <f7-icon material="close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button
          v-if="!mysettings.nofaculty"
          fab-close
          @click="editarNane('sta', null)"
          label="for faculty/staff"
          ><f7-icon material="school"></f7-icon
        ></f7-fab-button>
        <f7-fab-button
          fab-close
          @click="editarNane('stu', null)"
          label="for students"
          ><f7-icon material="people"></f7-icon
        ></f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { loadsettings } from "../mixins/loadsettings";

export default {
  mixins: [loadsettings],
  data() {
    return {
      grades: {},
      contactsQuestion: "",
      defaultQuestion: 
        "Have you been exposed to a household or close family member who has tested positive for or is suspected of having COVID-19 in the past 14 days?",
    };
  },
  computed: {
    ...mapState(["userinfo", "mysettings"]),
    // ...mapGetters(['']), mysettings.nofaculty
    sta() {
      let questions = this.mysettings.qstns || {};
      let sta = questions.sta || {};
      let arrayed = Object.values(sta) || [];
      arrayed.sort((a, b) => {
        return a.o - b.o;
      });
      return arrayed;
    },
    stu() {
      let questions = this.mysettings.qstns || {};
      let stu = questions.stu || {};
      let arrayed = Object.values(stu) || [];
      arrayed.sort((a, b) => {
        return a.o - b.o;
      });
      return arrayed;
    },
  },
  mounted() {
    this.$f7.sortable.enable(".mystuquestions");
    this.$f7.sortable.enable(".mystaquestions");

    this.contactsQuestion = 
        this.mysettings.contacts_question || this.defaultQuestion;
  },
  created() {},
  methods: {
    editarNane(stx, id) {
      let string = id ? "Edit " : "New ";
      this.$f7.dialog.prompt(
        "Symptom description",
        string + "symptom",
        (name) => {
          if (!name) {
            return this.$f7.dialog.alert("This field can not be empty");
          } else {
            name = name.trim();
            let finalindice, tosave;
            let updates = {};
            console.log("grabando.", id, stx);
            this.$f7.dialog.preloader("Saving...");
            if (!id) {
              let indice = name.toLowerCase();
              let preindsud = indice.split(".").join("");
              let noslash = preindsud.split("/").join("");
              finalindice = "cu-" + noslash.split(" ").join("");
              tosave = {
                i: finalindice,
                n: name,
                o: Math.floor(Date.now() / 1000),
              };
              updates[
                "settings/" +
                  this.userinfo.uid +
                  "/qstns/" +
                  stx +
                  "/" +
                  finalindice
              ] = tosave;
            } else {
              updates[
                "settings/" +
                  this.userinfo.uid +
                  "/qstns/" +
                  stx +
                  "/" +
                  id +
                  "/n"
              ] = name;
            }
            this.$firebase
              .database()
              .ref()
              .update(updates)
              .then(() => {
                this.$f7.dialog.close();
                if (!id) {
                  this.$store.commit("updatesymtopm", {
                    i: finalindice,
                    to: tosave,
                    stx: stx,
                  });
                } else {
                  let prevqstns = this.mysettings.qstns || {};
                  let previousqusx = prevqstns[stx] || {};
                  let simpomt = Object.assign({}, previousqusx[id]);
                  simpomt.n = name;
                  this.$store.commit("updatesymtopm", {
                    i: id,
                    to: simpomt,
                    stx: stx,
                  });
                }
              })
              .catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, "Error");
              });
          }
        },
        () => {
          console.log("canceling");
        },
        id ? this.mysettings.qstns[stx][id].n : null
      );
    },
    onSort(data, stx) {
      // Sort data
      this.$f7.dialog.preloader("Saving new order...");
      console.log(data);
      let prevqstns = Object.assign({}, this.mysettings.qstns || {});
      let previousqusx = prevqstns[stx] || {};
      let clonado = this[stx].slice(0);
      clonado.splice(data.to, 0, clonado.splice(data.from, 1)[0]);
      console.log("listo es", clonado);
      clonado.forEach((element, idx) => {
        previousqusx[element.i].o = idx;
      });
      console.log(previousqusx);
      this.$firebase
        .database()
        .ref("settings/" + this.userinfo.uid + "/qstns/" + stx)
        .set(previousqusx)
        .then(() => {
          this.$f7.dialog.close();
          this.$store.commit("updateSTXquestions", {
            what: stx,
            to: previousqusx,
          });
        })
        .catch((error) => {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message, "Error");
        });
    },
    cambiand(simtpimid, stx, valor) {
      console.log("cambiando", simtpimid, valor);
      let hide = valor ? null : true;
      this.$f7.dialog.preloader("Saving...");
      this.$firebase
        .database()
        .ref(
          "settings/" +
            this.userinfo.uid +
            "/qstns/" +
            stx +
            "/" +
            simtpimid +
            "/hide"
        )
        .set(hide)
        .then(() => {
          this.$f7.dialog.close();
          let pregradeinfo = this.mysettings.qstns[stx][simtpimid];
          pregradeinfo.hide = hide;
          this.$store.commit("updatesymtopm", {
            i: simtpimid,
            to: pregradeinfo,
            stx: stx,
          });
        })
        .catch((error) => {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message, "Error");
        });
    },

    editContactsQuestion() {
      const prevQuestion = this.contactsQuestion;
      this.$f7.dialog.prompt(
        "Edit the contacts question",
        "Contacts question",
        (newValue) => {
          if (prevQuestion === newValue) {
            return;
          } else if (!newValue.trim()) {
            return this.$f7.dialog.alert("This field can not be empty");
          }

          this.$f7.dialog.preloader("Editing contacts question...");

          this.$firebase
            .database()
            .ref(`/settings/${this.userinfo.uid}/contacts_question`)
            .set(newValue)
            .then(() => {
              this.contactsQuestion = newValue;
              this.$f7.dialog.close();
            })
            .catch((error) => {
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message, "Error");
            });
        },
        () => {},
        this.contactsQuestion
      );
    },

    setConctactsQuestionDefault() {
      this.$f7.dialog.confirm(
        `Are you sure you want to set this question to the default question?`, "Set to default", () => {
          if (this.contactsQuestion === this.defaultQuestion) {
            return;
          }
          
          this.$f7.dialog.preloader("Setting to default...");

          this.$firebase
            .database()
            .ref(`/settings/${this.userinfo.uid}/contacts_question`)
            .set(null)
            .then(() => {
              this.contactsQuestion = this.defaultQuestion;

              this.$f7.dialog.close();
            })
            .catch(error => {
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message, "Error");
            })
      });
    }
  },
};
</script>
<style>
.mystuquestions {
  margin-bottom: 80px !important;
}

.contacts-question {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>