import {mapState, mapGetters} from 'vuex'

export const deletestu = {
    data () {
        return {
          
        };
      },
      computed:{
        ...mapState(['students','userinfo']),

      },

    methods:{
      deleteStudentsArray(studentsToDelete){
        return new Promise((resolve,reject)=>{
          let studnts={};
          let updates={};
          let promesexToGetCodes=[];
          studentsToDelete.forEach(studentid => {
            promesexToGetCodes.push(this.$firebase.database().ref('codes/'+this.userinfo.uid).orderByChild('stid').equalTo(studentid).once('value'));
          });
          return Promise.all(promesexToGetCodes).then(multisnahp=>{
            multisnahp.forEach((snpashot,indx) => {
              let studenitlocal=studentsToDelete[indx];
              studnts[studenitlocal]=this.students[studenitlocal]
              studnts[studenitlocal].inkt=true
              updates['students/'+this.userinfo.uid+'/'+studenitlocal+'/inkt'] = true;
              if(studnts[studenitlocal].prtemails){
                Object.keys(studnts[studenitlocal].prtemails).forEach(unpartemail => {
                  updates['localprivs/'+this.userinfo.uid+'/'+unpartemail+'/stus/'+studenitlocal] = null;
                });
                updates['students/'+this.userinfo.uid+'/'+studenitlocal+'/prtemails'] = null;
                 studnts[studenitlocal].prtemails=null
              }
              if(snpashot.exists()){
                snpashot.forEach(codesnap => {
                    updates['codes/'+this.userinfo.uid+'/'+codesnap.key] = null;
                });
              }
            });
            console.log(updates)
            return this.$firebase.database().ref().update(updates)
          }).then(()=>{
            this.$store.commit('mergeObjTo',{what:'students',to:studnts});
            resolve(true);
          }).catch(error=>{
            reject (error);
          })
        });
      }
    }
}