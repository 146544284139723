<template>
    <f7-card>
        <f7-card-header class="cardheaderflex">
             <f7-list class="liboldtitle no-margin-top no-margin-bottom" media-list>
                <f7-list-item
                    header="Grade/level"
                    title="Visitors"
                >
                </f7-list-item>
            </f7-list>
        </f7-card-header>

        <f7-card-content>
            <f7-list :class="loading?'skeleton-text skeleton-effect-blink':null" class="fixic datalistxd">
                <f7-list-item
                    title="Visitors cleared"
                    footer="Daily checks completed"
                    :after="dailyChecks || '0'"
                >
                    <f7-icon color="primary" material="assignment_turned_in" slot="media"></f7-icon>
                </f7-list-item>

                <f7-list-item
                    title="Admission scans"
                    :after="scans || '0'"
                >
                    <f7-icon material="important_devices" slot="media"></f7-icon>

                </f7-list-item>
            </f7-list>
        </f7-card-content>

        <f7-card-footer>
            <f7-link></f7-link>
            <f7-button :href="'/visitorsdailydata/'+formattedDate" outline>Details</f7-button>
        </f7-card-footer>
    </f7-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["fechafocus"],

    data() {
        return {
            dailyChecks: 0,
            scans: 0,

            loading: false,
        };
    },

    computed: {
        ...mapState(["visitors", "userinfo"]),

        formattedDate() {
            return this.fechafocus.split('/').join('-');
        },
    },

    watch: {
        fechafocus() {
            this.fetchVisitorsData();
        }
    },

    mounted() {
        this.fetchVisitorsData();
    },

    methods: {
        fetchVisitorsData() {
            this.loading = true;

            let rightNow = this.$moment(this.fechafocus,'MM/DD/YYYY');
            let year = rightNow.format('YYYY');
            let month = rightNow.format('MM');
            let day = rightNow.format('DD');

            let promises = [
                this.$firebase.database().ref('daily/'+this.userinfo.uid+'/byvisdaily/'+year+'/'+month+'/'+day).once('value'),
                this.$firebase.database().ref('skns/'+this.userinfo.uid+'/visitors/'+year+'/'+month+'/'+day).once('value')
            ];

            Promise.all(promises).then((snapshots) => {
                let dailyChecks = snapshots[0].val() || {};
                let scans = snapshots[1].val() || {};

                this.dailyChecks = Object.keys(dailyChecks).length;
                this.scans = Object.keys(scans).length;

                let visitorsPromises = [];

                for (const vsid in dailyChecks) {
                    visitorsPromises.push(this.$firebase.database().ref(`/visitors/${this.userinfo.uid}/${vsid}`).once('value'));
                }

                let visitors = {};

                Promise.all(visitorsPromises).then((snapshots) => {
                    snapshots.forEach(snapshot => {
                        let visitor = snapshot.val() || {};

                        if (Object.values(visitor).length) {
                            this.$set(visitors, visitor.vsid, visitor);
                            this.$set(visitors[visitor.vsid], "daily", dailyChecks[visitor.vsid]);
                            this.$set(visitors[visitor.vsid], "scans", scans[visitor.vsid]);
                        }
                    });

                    this.$store.commit("setVisitorsToday", visitors);

                    this.loading = false;
                });
            })
        }
    },
}
</script>